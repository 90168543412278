import { v4 as uuidv4 } from "uuid";

import {
  filterNonNull,
  isEmpty,
  setEnglishAsDefaultLanguage
} from "helpers/lib";
import { IField, IRow, ISubField, IGroup, ILogic } from "types/template.model";

// jest.mock("uuid", () => ({ v4: () => "00000000-0000-0000-0000-000000000000" }));

export const convertChildField = (
  data: ISubField,
  dataSource: "none" | "api"
) => {
  const childFormulars: any = [];
  Object.entries(data.formulas).forEach(([group, groupValues]: any) => {
    Object.entries(groupValues).forEach(([period, parentLogicValues]: any) =>
      Object.entries(parentLogicValues).forEach(
        ([parentLogic, formulaValues]: any) =>
          Object.entries(formulaValues).forEach(([logic, formula]: any) => {
            childFormulars.push({
              id: uuidv4(),
              subFieldId: data.id,
              dataSource,
              system_index: data.system_index,
              group,
              period,
              formula,
              default_format: data.formats?.[group] ?? null,
              parent_logic: parentLogic,
              calc_type: logic,
              type: data.type,
              title: setEnglishAsDefaultLanguage(data.title)
            });
          })
      )
    );
  });

  return childFormulars;
};

export const convertPlugRow = (data: ISubField) => {
  if (!isEmpty(data)) {
    let plugRow: any = { ...data, default_format: null };
    for (const logic in data.formulas.historical) {
      if (logic) {
        plugRow = {
          ...plugRow,
          subFieldId: data.id,
          title: setEnglishAsDefaultLanguage(data.title),
          historical: {
            calc_type: logic,
            logic: data.formulas.historical[logic]
          }
        };
      }
    }

    for (const logic in data.formulas.projection) {
      if (logic) {
        plugRow = {
          ...plugRow,
          subFieldId: data.id,
          title: setEnglishAsDefaultLanguage(data.title),
          projection: {
            calc_type: logic,
            logic: data.formulas.projection[logic]
          }
        };
      }
    }

    delete plugRow.formulas;

    return plugRow;
  }
  return {};
};

export const convertHelperField = (data: ISubField) => {
  const helperFields: any[] = [];

  Object.entries(data.formulas).forEach(([group, groupValues]: any) =>
    Object.entries(groupValues).forEach(([period, periodValues]: any) =>
      Object.entries(periodValues).forEach(
        ([childStatus, childStatusValues]: any) =>
          Object.entries(childStatusValues).forEach(
            ([logic, logicValues]: any) =>
              helperFields.push({
                id: uuidv4(),
                subFieldId: data.id,
                calc_type: logic,
                formula: logicValues,
                group,
                period,
                default_format: data.formats?.[group] ?? null,
                child_status: childStatus,
                system_index: data.system_index,
                title: setEnglishAsDefaultLanguage(data.title),
                display_instead_of_parent: data.display_instead_of_parent
              })
          )
      )
    )
  );

  return helperFields;
};

export const convertFormulars = (logic: IField, dataSource: "none" | "api") => {
  const metadata = logic.meta_data.groups.map((data) => ({
    ...data,
    isDefault: logic.meta_data.default_group === data.name
  }));

  const parentFieldArray: any = [];

  const childFields = logic.subfields.filter(
    (sub: ISubField) => sub.type === "child"
  );

  const convertedChildFields = childFields
    .map((item: ISubField) => convertChildField(item, dataSource))
    .flat();

  const helperFields = logic.subfields.filter(
    (sub: ISubField) =>
      sub.type === "helper" && !sub.system_index.includes("child")
  );

  const childHelperFields = logic.subfields.filter(
    (sub: ISubField) =>
      sub.type === "helper" && sub.system_index.includes("child")
  );

  const convertedHelperFields = helperFields
    .map((helper: ISubField) => convertHelperField(helper))
    .flat();

  const convertedChildHelperFields = childHelperFields
    .map((helper: ISubField) => convertHelperField(helper))
    .flat();

  const plugRow = logic.subfields.filter(
    (sub: ISubField) => sub.type === "plug"
  );

  Object.entries(logic.formulas).forEach(([group, groupValues]: any) =>
    Object.entries(groupValues).forEach(([period, periodValues]: any) =>
      Object.entries(periodValues).forEach(
        ([childStatus, childStatusValues]: any) =>
          Object.entries(childStatusValues).forEach(
            ([calculationType, formula]) => {
              parentFieldArray.push({
                id: uuidv4(),
                period,
                group,
                child_status: childStatus,
                calc_type: calculationType,
                data_source: dataSource,
                formula,
                child_field:
                  childStatus === "with_child"
                    ? {
                        ...convertedChildFields.filter(
                          (sub: any) =>
                            sub.system_index.includes(logic.system_index) &&
                            sub.period === period &&
                            sub.group === group
                        )[0],
                        helper_fields: convertedChildHelperFields.filter(
                          (helper: any) =>
                            helper.period === period && helper.group === group
                        )
                      }
                    : {
                        helper_fields: []
                      },
                plug_row: convertPlugRow(plugRow[0]),
                plug_row_status: logic.subfields.some(
                  (sub: any) => sub.type === "plug"
                ),
                helper_fields: convertedHelperFields.filter(
                  (helper: any) =>
                    helper.child_status === childStatus &&
                    helper.period === period &&
                    helper.group === group
                ),

                open: true
              });
            }
          )
      )
    )
  );

  const arrayWithLogicSetIds = parentFieldArray.map((item: any) => ({
    ...item,
    logicSetId: `historical_projection_${item.child_status}`
  }));

  const logicGroups = metadata.map((item, index) => ({
    ...item,
    id: `group_${index + 1}`,
    logics: [...arrayWithLogicSetIds]
      .filter((logicItem: any) => logicItem.group === item.name)
      .sort((logic1: any, logic2: any) =>
        logic1.logicSetId.localeCompare(logic2.logicSetId)
      )
  }));

  return logicGroups;
};

export const reduceHelperFields = (helpers: any) =>
  helpers
    .reduce((prev: any, current: any) => {
      const existingHelper = prev.find(
        (item: any) => item.system_index === current.system_index
      );

      if (!existingHelper) {
        prev.push(current);
      } else {
        prev.push({
          ...existingHelper,
          ...current
        });

        prev.splice(prev.indexOf(existingHelper), 1);
      }

      return prev;
    }, [])
    .sort((a: any, b: any) => a.system_index.localeCompare(b.system_index));

const getFormatsBySystemIndex = (formats: any, systemIndex: string) => {
  let currentFormats = {};
  Object.entries(formats).forEach(([key, value]: any) => {
    currentFormats = {
      ...currentFormats,
      [key]: value[systemIndex]
    };
  });

  return currentFormats;
};

export const convertArrayToFormulas = (array: IGroup[]) => {
  const newArray = [...array].map((group) => group.logics).flat();

  const subfields: ISubField[] = [];
  const helperFields: any[] = [];
  let childField: any = {};
  const childHelpers: any[] = [];
  let fieldObj = {
    formulas: {},
    subfields: [] as ISubField[]
  };
  let formulaObj: any = {};
  let plugRowObj: any = {};

  let helperFormats: any = {};
  let childHelperFormats: any = {};

  newArray.forEach((arr: any) => {
    let helperFormulas: any = {};
    const group = formulaObj[arr.group] ? formulaObj[arr.group] : {};

    const period =
      formulaObj[arr.group] && formulaObj[arr.group][arr.period]
        ? formulaObj[arr.group][arr.period]
        : {};

    formulaObj = {
      ...formulaObj,
      [arr.group]: {
        ...group,
        [arr.period]: {
          ...period,
          [arr.child_status]: {
            [arr.calc_type]: arr.formula
          }
        }
      }
    };

    // child
    const currentChildField = arr.child_field;

    const currentChildGroup =
      childField.formulas && childField.formulas[arr.group]
        ? childField.formulas[arr.group]
        : {};
    const currentChildPeriod = currentChildGroup
      ? currentChildGroup[arr.period]
      : {};
    const currentChildCalcType = currentChildPeriod
      ? currentChildPeriod[arr.calc_type]
      : {};
    const currentChildFormula: any = childField.formulas
      ? {
          ...childField.formulas,
          [arr.group]: {
            ...currentChildGroup,
            [arr.period]: {
              ...currentChildPeriod,
              [arr.calc_type]: {
                ...currentChildCalcType,
                [arr.child_field.calc_type]: arr.child_field.formula
              }
            }
          }
        }
      : {
          [arr.group]: {
            [arr.period]: {
              [arr.calc_type]: {
                [arr.child_field.calc_type]: arr.child_field.formula
              }
            }
          }
        };
    const currentChildFormats: Record<string, string> = childField.formats
      ? {
          ...childField.formats,
          [currentChildField.group]: currentChildField.default_format
        }
      : {
          [currentChildField.group]: currentChildField.default_format
        };

    childField =
      arr.child_status === "with_child"
        ? {
            id: currentChildField.subFieldId,
            title: currentChildField.title,
            system_index: currentChildField.system_index,
            type: "child",
            formats: !isEmpty(filterNonNull(currentChildFormats))
              ? filterNonNull(currentChildFormats)
              : null,
            formulas: {
              ...currentChildFormula,
              [arr.group]: {
                ...currentChildFormula[arr.group],
                [arr.period]: {
                  [arr.calc_type]: {
                    [arr.child_field.calc_type]: arr.child_field.formula
                  }
                }
              }
            }
          }
        : childField;
    // child helpers
    const currentChildHelpers =
      arr.child_field && arr.child_field.helper_fields
        ? arr.child_field.helper_fields
        : [];

    const childHelperList = currentChildHelpers.map((helper: any) => {
      let childHelperFormulas: any = {};

      const existingChildHelper = reduceHelperFields(childHelpers.flat()).find(
        (childHelper: any) => childHelper.system_index === helper.system_index
      );

      if (existingChildHelper) {
        childHelperFormulas = { ...existingChildHelper.formulas };
      }

      const helperGroup =
        childHelperFormulas && childHelperFormulas[helper.group]
          ? childHelperFormulas[helper.group]
          : {};

      const helperPeriod =
        childHelperFormulas &&
        childHelperFormulas[helper.group] &&
        childHelperFormulas[helper.group][helper.period]
          ? childHelperFormulas[helper.group][helper.period]
          : {};

      childHelperFormulas = {
        ...childHelperFormulas,
        [helper.group]: {
          ...helperGroup,
          [helper.period]: {
            ...helperPeriod,
            [helper.child_status]: {
              [helper.calc_type]: helper.formula
            }
          }
        }
      };

      childHelperFormats = {
        ...helperFormats,
        [helper.group]: {
          ...helperFormats[helper.group],
          [helper.system_index]: helper.default_format
        }
      };

      return {
        id: helper.subFieldId,
        title: helper.title,
        system_index: helper.system_index,
        type: "helper",
        formulas: childHelperFormulas,
        formats: getFormatsBySystemIndex(
          childHelperFormats,
          helper.system_index
        )
      };
    });

    childHelpers.push(childHelperList.flat());

    // plug row
    const plugRowHistorical =
      plugRowObj.formulas && plugRowObj.formulas.historical
        ? {
            calc_type: Object.entries(plugRowObj.formulas.historical).flat()[0],
            logic: Object.entries(plugRowObj.formulas.historical).flat()[1]
          }
        : arr.plug_row.historical;
    const plugRowProjection =
      plugRowObj.formulas && plugRowObj.formulas.projection
        ? {
            calc_type: Object.entries(plugRowObj.formulas.projection).flat()[0],
            logic: Object.entries(plugRowObj.formulas.projection).flat()[1]
          }
        : arr.plug_row.projection;

    if (!isEmpty(arr.plug_row)) {
      plugRowObj = {
        id: arr.plug_row.subFieldId,
        title: arr.plug_row.title,
        system_index: arr.plug_row.system_index,
        type: arr.plug_row.type,
        formulas: {
          ...plugRowObj.formulas,
          historical: {
            [plugRowHistorical.calc_type]: plugRowHistorical.logic
          },
          projection: {
            [plugRowProjection.calc_type]: plugRowProjection.logic
          }
        }
      };

      if (isEmpty(plugRowHistorical.logic))
        delete plugRowObj.formulas.historical;
      if (isEmpty(plugRowProjection.logic))
        delete plugRowObj.formulas.projection;
    }

    if (isEmpty(plugRowObj.formulas)) {
      plugRowObj = {};
    }

    // helper fields
    const helperFieldList = arr.helper_fields.map((helper: any) => {
      if (helper.formats) {
        helperFormats = { ...helper.formats };
      }

      const existingHelper = reduceHelperFields(helperFields.flat()).find(
        (currentHelper: any) =>
          currentHelper.system_index === helper.system_index
      );

      if (existingHelper) {
        helperFormulas = { ...existingHelper.formulas };
      }

      const helperGroup =
        helperFormulas && helperFormulas[helper.group]
          ? helperFormulas[helper.group]
          : {};

      const helperPeriod =
        helperFormulas &&
        helperFormulas[helper.group] &&
        helperFormulas[helper.group][helper.period]
          ? helperFormulas[helper.group][helper.period]
          : {};

      helperFormulas = {
        ...helperFormulas,
        [helper.group]: {
          ...helperGroup,
          [helper.period]: {
            ...helperPeriod,
            [helper.child_status]: {
              [helper.calc_type]: helper.formula
            }
          }
        }
      };

      helperFormats = {
        ...helperFormats,
        [helper.group]: {
          ...helperFormats[helper.group],
          [helper.system_index]: helper.default_format
        }
      };

      return {
        id: helper.subFieldId,
        title: helper.title,
        system_index: helper.system_index,
        type: "helper",
        formulas: helperFormulas,
        formats: getFormatsBySystemIndex(helperFormats, helper.system_index),
        display_instead_of_parent: helper.display_instead_of_parent
      };
    });

    helperFields.push(helperFieldList);
  });

  if (!isEmpty(childField)) subfields.push(childField);
  if (!isEmpty(plugRowObj)) subfields.push(plugRowObj);
  // if (!isEmpty(helperFields))
  subfields.push(...reduceHelperFields(helperFields.flat()));
  if (!isEmpty(childHelpers))
    subfields.push(...reduceHelperFields(childHelpers.flat()));
  fieldObj = {
    ...fieldObj,
    formulas: formulaObj,
    subfields
  };

  return fieldObj;
};

export const generateSystemIndex = (list: IRow[]) => {
  if (list.length === 0) {
    return `IS_100`;
  }
  const systemIndexList = list
    .map((item: IRow) =>
      Number(item.system_index.substring(item.system_index.indexOf("_") + 1))
    )
    .sort((a: number, b: number) => a - b);

  const lastItem = systemIndexList[systemIndexList.length - 1];
  const newSystemIndex = `IS_${Number(lastItem) + 100}`;
  return newSystemIndex;
};

export const generateSubSystemIndex = (
  systemIndex: string,
  list: any[],
  suffix: string
) => {
  if (list.length === 0) {
    return `${systemIndex}_${suffix}`;
  }

  const indexList = [...list]
    .sort((a: IRow, b: IRow) => a.system_index.localeCompare(b.system_index))
    .map((item: IRow) => {
      const spittedSystemIndex = item.system_index.split("_");
      return Number.isNaN(
        Number(spittedSystemIndex[spittedSystemIndex.length - 1])
      )
        ? 0
        : Number(spittedSystemIndex[spittedSystemIndex.length - 1]);
    });

  const lastItem = indexList[indexList.length - 1];

  const newSystemIndex = `${systemIndex}_${suffix}_${
    Number.isNaN(Number(lastItem)) ? 1 : Number(lastItem) + 1
  }`;

  return newSystemIndex;
};

export const filterRowsByDataSource = (rows: IRow[], dataSource: string) =>
  rows.filter((row) => row.data_source === dataSource);

export const convertGroupsToMetadata = (groups: IGroup[]) => {
  const metadataGroups = groups.map((group) => ({
    name: group.name,
    explanations: group.explanations
  }));
  const defaultGroup = groups.find((group) => group.isDefault)?.name;

  return {
    groups: metadataGroups,
    default_group: defaultGroup || "default"
  };
};

export const convertRowsToFields = (rows: IRow[]) =>
  rows.map((rowItem: IRow) => ({
    id: rowItem.fieldId,
    system_index: rowItem.system_index,
    title: rowItem.title,
    main_periods_logic: rowItem.main_periods_logic,
    interval: rowItem.interval,
    available_on_assumption_page: rowItem.available_on_assumption_page,
    meta_data: convertGroupsToMetadata(rowItem.groups),
    ...convertArrayToFormulas(rowItem.groups),
    worksheet: rowItem.worksheet,
    order_number: rowItem.order_number,
    default_format: rowItem.default_format
  }));

export const convertRowsToSubTemplates = (rows: IRow[]) => {
  const apiSubTemplates = filterRowsByDataSource(rows, "api");
  const noneSubTemplates = filterRowsByDataSource(rows, "none");

  const result = [
    {
      id: apiSubTemplates[0]?.subTemplateId,
      data_source: "api" as "none" | "api",
      fields: convertRowsToFields(apiSubTemplates)
    },
    {
      id: noneSubTemplates[0]?.subTemplateId,
      data_source: "none" as "none" | "api",
      fields: convertRowsToFields(noneSubTemplates)
    }
  ];

  return result;
};

// validation
export const validateRow = (currentRow: IRow, rowList: IRow[]) => {
  if (rowList.length === 0) return "";

  const existingRowWithSystemIndex = rowList.filter(
    (row) => row.system_index === currentRow.system_index
  );
  if (existingRowWithSystemIndex.length > 1)
    return "This row system index is already existed";

  return "";
};

export const validateGroup = (currentGroup: IGroup, groupList: IGroup[]) => {
  if (groupList.length === 0) return "";

  const existingGroups = groupList.filter(
    (group) => group.name === currentGroup.name
  );
  const defaultGroups = groupList.filter((group) => group.isDefault);

  if (existingGroups.length > 1) return "This group is already existed";
  if (defaultGroups.length > 1) return "Only one group can be default";
  if (defaultGroups.length === 0)
    return "There has to be at least one default group";
  return "";
};

export const validateRowLogic = (currentLogic: ILogic, logicList: ILogic[]) => {
  if (
    currentLogic.data_source &&
    currentLogic.period &&
    currentLogic.child_status
  ) {
    const existingLogics = logicList.filter(
      (logic) =>
        logic.data_source === currentLogic.data_source &&
        logic.period === currentLogic.period &&
        logic.child_status === currentLogic.child_status
    );
    if (existingLogics.length > 1) return "This logic is already existed";
    return "";
  }
  return "";
};
