import { SVGProps } from "react";

export const MoreVerticalIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="5"
    height="16"
    viewBox="0 0 5 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.5 12C3.03043 12 3.53914 12.2107 3.91421 12.5858C4.28929 12.9609 4.5 13.4696 4.5 14C4.5 14.5304 4.28929 15.0391 3.91421 15.4142C3.53914 15.7893 3.03043 16 2.5 16C1.96957 16 1.46086 15.7893 1.08579 15.4142C0.710713 15.0391 0.499999 14.5304 0.499999 14C0.499999 13.4696 0.710714 12.9609 1.08579 12.5858C1.46086 12.2107 1.96957 12 2.5 12ZM2.5 6C3.03043 6 3.53914 6.21071 3.91421 6.58579C4.28929 6.96086 4.5 7.46957 4.5 8C4.5 8.53043 4.28929 9.03914 3.91421 9.41421C3.53914 9.78929 3.03043 10 2.5 10C1.96957 10 1.46086 9.78929 1.08579 9.41421C0.710714 9.03914 0.5 8.53043 0.5 8C0.5 7.46957 0.710714 6.96086 1.08579 6.58579C1.46086 6.21071 1.96957 6 2.5 6ZM2.5 -8.74228e-08C3.03043 -6.42368e-08 3.53914 0.210714 3.91421 0.585786C4.28929 0.960859 4.5 1.46957 4.5 2C4.5 2.53043 4.28929 3.03914 3.91421 3.41421C3.53914 3.78929 3.03043 4 2.5 4C1.96957 4 1.46086 3.78929 1.08579 3.41421C0.710714 3.03914 0.5 2.53043 0.5 2C0.5 1.46957 0.710714 0.960859 1.08579 0.585786C1.46086 0.210713 1.96957 -1.10609e-07 2.5 -8.74228e-08Z"
      fill="currentColor"
    />
  </svg>
);

export const MoreVerticalIconLarge = ({
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="7"
    viewBox="0 0 32 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="3" cy="3.7002" r="3" fill="#636363" />
    <circle cx="16" cy="3.7002" r="3" fill="#636363" />
    <circle cx="29" cy="3.7002" r="3" fill="#636363" />
  </svg>
);
