import { SVGProps } from "react";

export const PlayCircleFilledIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_98_676)">
      <path
        d="M9.99996 1.66669C5.39996 1.66669 1.66663 5.40002 1.66663 10C1.66663 14.6 5.39996 18.3334 9.99996 18.3334C14.6 18.3334 18.3333 14.6 18.3333 10C18.3333 5.40002 14.6 1.66669 9.99996 1.66669ZM8.33329 13.75V6.25002L13.3333 10L8.33329 13.75Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_98_676">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
