import { ILoginInfo, ILoginResponse, IRegisterInfo } from "types/auth.model";
import { authorizedRequest, unauthorizedRequest } from "./request";

/**
 * Sends a login request to the server with the provided email and password, and returns a Promise that resolves to the login response.
 *
 * @param {ILoginInfo} loginInfo - An object containing the email and password for the login request.
 * @return {Promise<ILoginResponse>} - A Promise that resolves to the login response from the server.
 */
const login = ({ email, password }: ILoginInfo): Promise<ILoginResponse> =>
  unauthorizedRequest.post("/api/auth/token/", { email, password });

/**
 * Refreshes the access token using the provided refresh token.
 *
 * @param {string} refresh - The refresh token used to generate a new access token.
 * @return {Promise<{ access: string }>} - A promise that resolves to an object containing the new access token.
 */
const refreshAccessToken = (refresh: string): Promise<{ access: string }> =>
  unauthorizedRequest.post("/api/auth/token/refresh/", { refresh });

/**
 * Registers a user with the provided registration information.
 *
 * @param {IRegisterInfo} registerInfo - The registration information for the user.
 * @return {Promise<void>} A promise that resolves with no value upon successful registration.
 */
const register = (registerInfo: IRegisterInfo): Promise<void> =>
  unauthorizedRequest.post("/api/users/customers/", { ...registerInfo });

/**
 * Sends a request to reset the password associated with the provided email.
 *
 * @param {string} email - The email address for which the password will be reset.
 * @return {Promise<void>} A promise that resolves with no value upon successful completion.
 */
const requestResetPassword = (email: string): Promise<void> =>
  unauthorizedRequest.post("/api/auth/password-reset/", { email });

/**
 * Validate a reset password token.
 *
 * @param {string} token - The reset password token to be validated.
 * @return {Promise<{ status: string }>} A promise that resolves to an object with the status of the validation.
 */
const validateTokenResetPassword = (
  token: string
): Promise<{ status: string }> =>
  unauthorizedRequest.post("/api/auth/password-reset/validate_token/", {
    token
  });

/**
 * Sends a request to confirm the reset password with the provided password and token.
 *
 * @param {string} password - The new password for the user.
 * @param {string} token - The token received from the password reset email.
 * @return {Promise<void>} A Promise that resolves when the password reset is confirmed.
 */
const confirmResetPassword = (password: string, token: string): Promise<void> =>
  unauthorizedRequest.post("/api/auth/password-reset/confirm/", {
    password,
    token
  });

/**
 * Sends a request to confirm the user's email address.
 *
 * @param {string} token - The email confirmation token.
 * @param {string} email - The user's email address.
 * @return {Promise<void>} - A promise that resolves when the request is complete.
 */
const emailConfirmation = (token: string, email: string): Promise<void> =>
  unauthorizedRequest.post("/api/auth/email-confirmation/", {
    activation_token: token,
    email
  });

/**
 * Resends the email confirmation for the given email address.
 *
 * @param {string} email - The email address to resend the confirmation for.
 * @return {Promise<void>} - A promise that resolves when the email confirmation is successfully resent.
 */
const resendEmailConfirmation = (email: string): Promise<void> =>
  unauthorizedRequest.post("/api/auth/resend-email-confirmation/", {
    email
  });

/**
 * Flag as this user already seen the tutorial
 *
 * @param {number} id - The ID of the user
 * @return {Promise<void>} A Promise that resolves
 */
const checkTutorial = (id: number): Promise<void> =>
  authorizedRequest.patch(`/api/users/${id}/`, { is_checked_tutorial: true });

const authServices = {
  login,
  refreshAccessToken,
  register,
  requestResetPassword,
  validateTokenResetPassword,
  confirmResetPassword,
  emailConfirmation,
  resendEmailConfirmation,
  checkTutorial
};

export default authServices;
