import { SVGProps } from "react";

export const LogoutIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 15.2612V12.2612H6V8.26123H13V5.26123L18 10.2612L13 15.2612ZM11 0.26123C11.5304 0.26123 12.0391 0.471944 12.4142 0.847017C12.7893 1.22209 13 1.7308 13 2.26123V4.26123H11V2.26123H2V18.2612H11V16.2612H13V18.2612C13 18.7917 12.7893 19.3004 12.4142 19.6754C12.0391 20.0505 11.5304 20.2612 11 20.2612H2C1.46957 20.2612 0.960859 20.0505 0.585786 19.6754C0.210714 19.3004 0 18.7917 0 18.2612V2.26123C0 1.7308 0.210714 1.22209 0.585786 0.847017C0.960859 0.471944 1.46957 0.26123 2 0.26123H11Z"
      fill="#636363"
    />
  </svg>
);
