import { SVGProps } from "react";

export const ArrowsDiffIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-arrows-diff"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M11 16h10" />
    <path d="M11 16l4 4" />
    <path d="M11 16l4 -4" />
    <path d="M13 8h-10" />
    <path d="M13 8l-4 4" />
    <path d="M13 8l-4 -4" />
  </svg>
);
