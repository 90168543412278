import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IIndustry } from "types/industry.model";
import { initialState } from "./industries.types";
import {
  getActiveIndustries,
  getIndustries,
  getSectors
} from "./industries.services";

const industriesSlice = createSlice({
  name: "industries",
  initialState,
  reducers: {},
  extraReducers: {
    [getIndustries.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getIndustries.fulfilled.toString()]: (
      state,
      action: PayloadAction<IIndustry[]>
    ) => {
      state.loading = false;
      state.industries = [...action.payload];
    },
    [getIndustries.rejected.toString()]: (state) => {
      state.loading = false;
    },

    [getActiveIndustries.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getActiveIndustries.fulfilled.toString()]: (
      state,
      action: PayloadAction<IIndustry[]>
    ) => {
      state.loading = false;
      state.activeIndustries = [...action.payload];
    },
    [getActiveIndustries.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // sectors
    [getSectors.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getSectors.fulfilled.toString()]: (
      state,
      action: PayloadAction<any[]>
    ) => {
      state.loading = false;
      state.sectorList = [...action.payload];
    },
    [getSectors.rejected.toString()]: (state) => {
      state.loading = false;
    }
  }
});

export default industriesSlice.reducer;
