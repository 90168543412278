import { ReactNode } from "react";
import { createTheme, ThemeProvider, ThemeOptions } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#268DE9",
      dark: "#136dbd"
    },
    success: {
      main: "#61AB3E"
    },
    warning: {
      main: "#FFA722"
    },
    error: {
      main: "#e9262c"
    }
  },
  typography: {
    fontFamily: "'Inter', sans-serif"
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter', sans-serif",
          textTransform: "none",
          fontWeight: 600,
          borderRadius: 5,
          padding: "6px 15px"
        },
        outlined: {
          border: "2px solid #268DE9",
          "&:hover": {
            border: "2px solid #268DE9"
          }
        },
        sizeSmall: {
          padding: "6px 12px",
          height: "30px"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter', sans-serif"
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter', sans-serif"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter', sans-serif",
          padding: "18.5px 15px",
          fontSize: "1rem",
          lineHeight: 1.21
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter', sans-serif",
          fontWeight: 600
        }
      }
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true
      },
      styleOverrides: {
        tooltip: {
          fontSize: "0.875rem",
          lineHeight: "20px",
          backgroundColor: "rgba(51,51,51, 0.85)"
        },
        arrow: {
          color: "rgba(51,51,51, 0.85)"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter', sans-serif",
          "&.MuiOutlinedInput-root.Mui-disabled": {
            borderColor: "rgba(99, 99, 99, 0.3)",
            backgroundColor: "rgba(99, 99, 99, 0.12)"
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:not(:last-child)": {
            "& td": {
              borderBottom: "1px solid #d9d9d9"
            }
          },
          "& td": {
            "&:not(:last-child)": {
              borderRight: "1px solid #d9d9d9"
            }
          }
        }
      }
    }
  }
} as ThemeOptions);

interface ThemeProps {
  children: ReactNode;
}

export const Theme = ({ children }: ThemeProps) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
