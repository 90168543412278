import { useRef } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";

import { HCAPTCHA_SITE_KEY } from "constants/config";

interface CaptchaProps {
  onVerify: (token: string, key: string) => void;
  onExpire: () => void;
}

export default function Captcha({ onVerify, onExpire }: CaptchaProps) {
  const captchaRef = useRef<HCaptcha>(null);

  return (
    <HCaptcha
      sitekey={HCAPTCHA_SITE_KEY}
      onVerify={onVerify}
      onExpire={onExpire}
      ref={captchaRef}
    />
  );
}
