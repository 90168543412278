import { SVGProps } from "react";

export const ChevronUpIcon2 = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.99582 7.13544C9.88253 7.13544 9.77384 7.15695 9.66974 7.19998C9.56563 7.24301 9.47387 7.30422 9.39447 7.38362L5.44816 11.3299C5.26743 11.5107 5.18054 11.7167 5.18749 11.9479C5.19443 12.1792 5.28592 12.3829 5.46195 12.5589C5.63798 12.7349 5.84163 12.8229 6.07291 12.8229C6.30418 12.8229 6.50784 12.7349 6.68386 12.5589L9.99999 9.22194L13.3369 12.5589C13.513 12.7349 13.7166 12.8195 13.9479 12.8125C14.1792 12.8056 14.3828 12.7141 14.5589 12.5381C14.7349 12.362 14.8229 12.1584 14.8229 11.9271C14.8229 11.6958 14.7325 11.4902 14.5518 11.3103L10.6055 7.38362C10.5206 7.30422 10.426 7.24301 10.3219 7.19998C10.2178 7.15695 10.1091 7.13544 9.99582 7.13544Z"
      fill="currentColor"
    />
  </svg>
);
