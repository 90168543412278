import { SVGProps } from "react";

export const DeleteIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.83301 17.5C5.37467 17.5 4.98231 17.3368 4.65592 17.0104C4.32954 16.684 4.16634 16.2917 4.16634 15.8333V5C3.93023 5 3.73231 4.92014 3.57259 4.76042C3.41287 4.60069 3.33301 4.40278 3.33301 4.16667C3.33301 3.93056 3.41287 3.73264 3.57259 3.57292C3.73231 3.41319 3.93023 3.33333 4.16634 3.33333H7.49967C7.49967 3.09722 7.57954 2.89931 7.73926 2.73958C7.89898 2.57986 8.0969 2.5 8.33301 2.5H11.6663C11.9025 2.5 12.1004 2.57986 12.2601 2.73958C12.4198 2.89931 12.4997 3.09722 12.4997 3.33333H15.833C16.0691 3.33333 16.267 3.41319 16.4268 3.57292C16.5865 3.73264 16.6663 3.93056 16.6663 4.16667C16.6663 4.40278 16.5865 4.60069 16.4268 4.76042C16.267 4.92014 16.0691 5 15.833 5V15.8333C15.833 16.2917 15.6698 16.684 15.3434 17.0104C15.017 17.3368 14.6247 17.5 14.1663 17.5H5.83301ZM14.1663 5H5.83301V15.8333H14.1663V5ZM8.33301 14.1667C8.56912 14.1667 8.76704 14.0868 8.92676 13.9271C9.08648 13.7674 9.16634 13.5694 9.16634 13.3333V7.5C9.16634 7.26389 9.08648 7.06597 8.92676 6.90625C8.76704 6.74653 8.56912 6.66667 8.33301 6.66667C8.0969 6.66667 7.89898 6.74653 7.73926 6.90625C7.57954 7.06597 7.49967 7.26389 7.49967 7.5V13.3333C7.49967 13.5694 7.57954 13.7674 7.73926 13.9271C7.89898 14.0868 8.0969 14.1667 8.33301 14.1667ZM11.6663 14.1667C11.9025 14.1667 12.1004 14.0868 12.2601 13.9271C12.4198 13.7674 12.4997 13.5694 12.4997 13.3333V7.5C12.4997 7.26389 12.4198 7.06597 12.2601 6.90625C12.1004 6.74653 11.9025 6.66667 11.6663 6.66667C11.4302 6.66667 11.2323 6.74653 11.0726 6.90625C10.9129 7.06597 10.833 7.26389 10.833 7.5V13.3333C10.833 13.5694 10.9129 13.7674 11.0726 13.9271C11.2323 14.0868 11.4302 14.1667 11.6663 14.1667Z"
      fill="currentColor"
    />
  </svg>
);
