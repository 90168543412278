import loadable from "@loadable/component";

export const TextField = loadable(() => import("./text-field"));
export const OutlinedInput = loadable(() => import("./outlined-input"));
export const CustomOutlinedInput = loadable(
  () => import("./custom-outlined-input")
);
export const CustomTextField = loadable(() => import("./custom-text-field"));
export const CustomInputBase = loadable(() => import("./custom-input-base"));
export const PhoneInput = loadable(() => import("./phone-input"));
export const OtpInput = loadable(() => import("./otp-input"));
