import { ReactNode } from "react";
import Popover, { PopoverProps } from "@mui/material/Popover";
import MenuList from "@mui/material/MenuList";

export interface ActionPopoverProps extends PopoverProps {
  children?: ReactNode;
}

export default function ActionPopover({
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left"
  },
  children,
  ...props
}: PopoverProps) {
  return (
    <Popover anchorOrigin={anchorOrigin} {...props}>
      <MenuList disablePadding>{children}</MenuList>
    </Popover>
  );
}
