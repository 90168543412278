import clsx from "clsx";

interface LanguageIdentifierProps {
  className?: string;
  language: string;
}

export default function LanguageIdentifier({
  className,
  language
}: LanguageIdentifierProps) {
  return (
    <div
      className={clsx(
        "mr-2 flex h-5 w-5 flex-shrink-0 items-center justify-center rounded bg-primary-50 font-inter text-xxs font-semibold uppercase text-gray-63",
        className
      )}
    >
      {language}
    </div>
  );
}
