import { SVGProps } from "react";

export const ChatArrowDownIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="16" cy="16" r="16" fill="url(#paint0_linear_211_2652)" />
    <path
      d="M15.1663 10.3334V20.4792L10.4997 15.8125L9.33301 17L15.9997 23.6667L22.6663 17L21.4997 15.8125L16.833 20.4792V10.3334H15.1663Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_211_2652"
        x1="0"
        y1="16"
        x2="32"
        y2="16"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#458DE2" />
        <stop offset="1" stopColor="#00897B" />
      </linearGradient>
    </defs>
  </svg>
);
