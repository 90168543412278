import { SVGProps } from "react";

export const HandleDragIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="9.5" cy="6.5" r="1.5" fill="currentColor" />
    <circle cx="9.5" cy="12" r="1.5" fill="currentColor" />
    <circle cx="9.5" cy="17.5" r="1.5" fill="currentColor" />
    <circle cx="14.5" cy="6.5" r="1.5" fill="currentColor" />
    <circle cx="14.5" cy="12" r="1.5" fill="currentColor" />
    <circle cx="14.5" cy="17.5" r="1.5" fill="currentColor" />
  </svg>
);
