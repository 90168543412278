import { SVGProps } from "react";

export const SquareAddIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.1 16.0125H12.9V12.2953H16.8V10.5797H12.9V6.8625H11.1V10.5797H7.20001V12.2953H11.1V16.0125ZM5.40001 19.4437C4.90501 19.4437 4.48126 19.2758 4.12876 18.9398C3.77626 18.6038 3.60001 18.1999 3.60001 17.7281V5.14687C3.60001 4.67508 3.77626 4.27119 4.12876 3.93521C4.48126 3.59924 4.90501 3.43125 5.40001 3.43125H18.6C19.095 3.43125 19.5188 3.59924 19.8713 3.93521C20.2238 4.27119 20.4 4.67508 20.4 5.14687V17.7281C20.4 18.1999 20.2238 18.6038 19.8713 18.9398C19.5188 19.2758 19.095 19.4437 18.6 19.4437H5.40001ZM5.40001 17.7281H18.6V5.14687H5.40001V17.7281Z"
      fill="#636363"
    />
  </svg>
);
