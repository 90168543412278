import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";

export const StyledMenuItem = styled(MenuItem)(() => ({
  borderRadius: "5px",
  color: "#636363",
  textTransform: "capitalize",
  "&:hover": {
    borderRadius: "5px",
    backgroundColor: "#E3F2FE"
  },
  "&.Mui-selected": {
    backgroundColor: "#ffffff",
    fontWeight: 600
  }
}));

export const MenuProps: any = {
  PaperProps: {
    style: {
      maxHeight: 210,
      overflowY: "hidden",
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
      paddingBottom: 8
    }
  },
  MenuListProps: {
    sx: {
      maxHeight: "calc(210px - 16px)",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "13px",
        background: "#ffffff"
      },
      "&::-webkit-scrollbar-track": {
        background: "#e5e5e5",
        borderLeft: "8px solid #ffffff"
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#cbcbcb",
        borderLeft: "8px solid #ffffff",
        backgroundClip: "padding-box"
      }
    }
  }
};

export const selectStyles = {
  backgroundColor: "#ffffff",
  fontFamily: "Inter",
  "& > div": {
    fontSize: "0.875rem",
    paddingRight: "31px !important"
  }
};
