/**
 * Splits an array into chunks of a specified size.
 *
 * @param {any[]} arr - The array to be split.
 * @param {number} size - The size of each chunk.
 * @return {any[][]} - An array of arrays, where each sub-array is a chunk of the original array.
 */
export const arrayChunk = (arr: any[], size: number): any[][] => {
  const result = [];
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size));
  }
  return result;
};
