export const menuListStyles: any = {
  maxHeight: "calc(210px - 16px)",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "13px",
    height: "5px",
    background: "#ffffff"
  },
  "&::-webkit-scrollbar-track": {
    background: "#e5e5e5",
    borderLeft: "8px solid #ffffff"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#cbcbcb",
    borderLeft: "8px solid #ffffff",
    backgroundClip: "padding-box"
  }
};

export const paperStyles: any = {
  maxHeight: 210,
  overflowY: "hidden",
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 8,
  paddingBottom: 8,
  width: "100%",
  zIndex: 9999,
  position: "absolute",
  boxShadow:
    "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)"
};
