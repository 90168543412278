import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ICurrentUser, ICustomer, IUserGroup } from "types/user.model";
import { userInitialState } from "./users.types";
import {
  getCustomerList,
  getUserDetail,
  getUserGroupList,
  resendPhoneVerification,
  verifyPhoneNumber
} from "./users.services";

const usersSlice = createSlice({
  name: "users",
  initialState: userInitialState,
  reducers: {},
  extraReducers: {
    [getUserDetail.pending.toString()]: (state) => {},
    [getUserDetail.fulfilled.toString()]: (
      state,
      action: PayloadAction<ICurrentUser>
    ) => {
      state.full_name = action.payload.full_name;
      state.public_username = action.payload.public_username;
      state.currentUser = action.payload;
      if (action.payload.subscription) {
        state.subscription.name = action.payload.subscription.name;
        state.subscription.is_free = action.payload.subscription.is_free;
      }
    },
    [getUserDetail.rejected.toString()]: (state) => {},

    // get customer list
    [getCustomerList.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getCustomerList.fulfilled.toString()]: (
      state,
      action: PayloadAction<ICustomer[]>
    ) => {
      state.loading = false;
      state.customerList = action.payload;
    },
    [getCustomerList.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // get user group list
    [getUserGroupList.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getUserGroupList.fulfilled.toString()]: (
      state,
      action: PayloadAction<IUserGroup[]>
    ) => {
      state.loading = false;
      state.userGroupList = action.payload;
    },
    [getUserGroupList.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // phone verification
    [resendPhoneVerification.pending.toString()]: (state) => {},
    [resendPhoneVerification.fulfilled.toString()]: (state) => {},
    [resendPhoneVerification.rejected.toString()]: (state) => {},

    [verifyPhoneNumber.pending.toString()]: (state) => {},
    [verifyPhoneNumber.fulfilled.toString()]: (state) => {},
    [verifyPhoneNumber.rejected.toString()]: (state) => {}
  }
});

export default usersSlice.reducer;
