import { useTranslation } from "react-i18next";

import { Button } from "components/button";
import Modal from "./modal";

interface LogoutModalProps {
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export default function LogoutModal({
  open,
  loading,
  onClose,
  onConfirm
}: LogoutModalProps) {
  const { t } = useTranslation();

  return (
    <Modal open={open} title={t("app:confirm-logout")} onClose={onClose}>
      <div className="px-4 pb-4">
        <p className="text-main">{t("app:confirm-logout-message")} ?</p>
        <div className="mt-4 flex justify-end">
          <Button
            disableElevation
            variant="outlined"
            className="mr-4"
            onClick={onClose}
          >
            {t("common:button.cancel")}
          </Button>
          <Button
            disableElevation
            loading={loading}
            variant="contained"
            onClick={onConfirm}
          >
            {t("common:header.logout")}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

LogoutModal.defaultProps = {
  loading: false
};
