import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";

export const StyledToggleButton = styled(ToggleButton)(() => ({
  textTransform: "none",
  padding: "12px 20px",
  fontFamily: "Inter",
  border: "1px solid #cbcbcb",
  backgroundColor: "#fff",
  height: "40px",
  "&.Mui-selected": {
    backgroundColor: "#268DE9",
    color: "#fff",
    "& svg rect": {
      fill: "#268DE9"
    },
    "& svg path": {
      fill: "#268DE9"
    }
  },
  "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderLeft: "1px solid #cbcbcb"
  }
}));
