import { ReactNode } from "react";
import { Route } from "react-router-dom";

import { IRoute } from "types/routes.model";

/**
 * Renders an array of routes as a list of React Router `<Route>` components.
 *
 * @param {IRoute[]} routes - An array of route objects
 * @return {React.ReactNode} - The rendered `<Route>` components
 */

export const renderRoutes = (routes: IRoute[]): ReactNode =>
  routes.map((route) => (
    <Route
      key={route.path}
      exact={route.exact}
      path={route.path}
      component={route.component}
    />
  ));
