import { SVGProps } from "react";

export const ArrowRightIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 6.00001L0 12L-5.24537e-07 0L8 6.00001Z" fill="currentColor" />
  </svg>
);
