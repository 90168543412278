import { createAsyncThunk } from "@reduxjs/toolkit";

import { authorizedRequest } from "services/request";

export const getUserDetail = createAsyncThunk(
  "user/getUserDetail",
  async (state, { rejectWithValue }) => {
    try {
      const response = await authorizedRequest.get("/api/users/me/");
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getCustomerList = createAsyncThunk(
  "user/getCustomerList",
  async (state, { rejectWithValue }) => {
    try {
      const response = await authorizedRequest.get("/api/users/customers/");
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getUserGroupList = createAsyncThunk(
  "user/getUserGroupList",
  async (state, { rejectWithValue }) => {
    try {
      const response = await authorizedRequest.get("/api/users/groups/");
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "user/updateUserProfile",
  async (
    state: {
      id: number;
      data: {
        company_name: string;
        position: string;
        phone_number: string;
        country_code: string;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await authorizedRequest.put(`/api/users/${state.id}`, {
        ...state.data
      });
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const resendPhoneVerification = createAsyncThunk(
  "user/resendPhoneVerification",
  async (
    state: {
      email: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await authorizedRequest.post(
        "/api/users/phone-verification-resend/",
        {
          email: state.email
        }
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const verifyPhoneNumber = createAsyncThunk(
  "user/verifyPhoneNumber",
  async (
    state: {
      email: string;
      code: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await authorizedRequest.post(
        "/api/users/phone-verification-approve/",
        {
          email: state.email,
          code: state.code
        }
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);
