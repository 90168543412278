import { createAsyncThunk } from "@reduxjs/toolkit";

import { authorizedRequest } from "services/request";

export const getRowLogics = createAsyncThunk(
  "rowLogics/getRowLogics",
  async (state, { rejectWithValue }) => {
    try {
      const response = await authorizedRequest.get(
        "/api/analyzes/calculation-types/"
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);
