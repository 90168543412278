import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IMember, IPermission, IAcceptedInvite } from "types/member.model";
import { initialState } from "./members.types";
import {
  getAcceptedInvites,
  getMembers,
  getPermissionsByInvitedMember
} from "./members.services";

const membersSlice = createSlice({
  name: "members",
  initialState,
  reducers: {},
  extraReducers: {
    // get members
    [getMembers.pending.toString()]: (state) => {
      state.loadingMember = true;
    },
    [getMembers.fulfilled.toString()]: (
      state,
      action: PayloadAction<IMember[]>
    ) => {
      state.loadingMember = false;
      state.members = [...action.payload];
    },
    [getMembers.rejected.toString()]: (state) => {
      state.loadingMember = false;
    },

    // get permissions
    [getPermissionsByInvitedMember.pending.toString()]: (state) => {
      state.loadingPermission = true;
    },
    [getPermissionsByInvitedMember.fulfilled.toString()]: (
      state,
      action: PayloadAction<IPermission[]>
    ) => {
      state.loadingPermission = false;
      state.permissions = [...action.payload];
    },
    [getPermissionsByInvitedMember.rejected.toString()]: (state) => {
      state.loadingPermission = false;
    },

    // get permissions
    [getAcceptedInvites.pending.toString()]: (state) => {
      state.loadingAcceptedInvites = true;
    },
    [getAcceptedInvites.fulfilled.toString()]: (
      state,
      action: PayloadAction<IAcceptedInvite[]>
    ) => {
      state.loadingAcceptedInvites = false;
      state.acceptedInvites = [...action.payload];
    },
    [getAcceptedInvites.rejected.toString()]: (state) => {
      state.loadingAcceptedInvites = false;
    }
  }
});

export default membersSlice.reducer;
