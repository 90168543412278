import { v4 as uuidv4 } from "uuid";
import { Column } from "react-table";

import { ICreateAnalysisResponse } from "types/analysis.model";
import {
  IVersionDataJson,
  IAnnualQuarterly,
  IDateTitles,
  IVersionDataField,
  IOverWrittenFieldsValues
} from "types/version-data.model";
import { calculateNumber, roundToDecimal, message } from "helpers/lib";
import { TFunction } from "i18next";
import { arrayChunk, getParentSystemIndex } from "helpers";
import { IConvertedMetadata, IDataValue, IPeriods } from "./statements.types";
import { formatList } from "./statements.constants";

type Period = {
  period: string;
};

type CustomColumn = Period & Column;

const calculateVariance = (actual: number, projected: number) => {
  if (Number(actual) === 0 || Number(projected) === 0) return 0;
  return ((Number(actual) - Number(projected)) / Number(actual)) * 100;
};

const convertAnnualQuarterlyData = (
  annualData: number[],
  quarterlyData: number[],
  indexObject: IAnnualQuarterly,
  unit: number,
  decimal: number,
  systemIndex: string,
  fields: IVersionDataField,
  comparableIndexes?: IAnnualQuarterly,
  expectedAnnualData?: number[],
  expectedQuarterlyData?: number[],
  isReplaceParent?: boolean
) => {
  let dataObj: IDataValue = {};
  annualData.forEach((item, index) => {
    const annualDataObj: IDataValue = {};
    let annualValue = item;
    if (fields[systemIndex].format === "percentage") {
      annualValue *= 100;
    }

    annualDataObj[`annual_${indexObject.annual[index]}`] =
      !systemIndex.includes("helper")
        ? roundToDecimal(calculateNumber("divide", annualValue, unit), decimal)
        : roundToDecimal(annualValue, 3);

    if (isReplaceParent) {
      annualDataObj[`annual_${indexObject.annual[index]}`] = roundToDecimal(
        annualValue,
        3
      );
    }

    dataObj = Object.assign(dataObj, annualDataObj);
  });

  comparableIndexes?.annual.forEach((item) => {
    const annualDataObj: IDataValue = {};
    let expectedAnnualValue = expectedAnnualData?.[item] ?? 0;
    if (fields[systemIndex].format === "percentage") {
      expectedAnnualValue *= 100;
    }
    annualDataObj[`annual_original_${item}`] = !systemIndex.includes("helper")
      ? roundToDecimal(
          calculateNumber("divide", expectedAnnualValue, unit),
          decimal
        )
      : roundToDecimal(expectedAnnualValue, 3);

    if (isReplaceParent) {
      annualDataObj[`annual_original_${item}`] = roundToDecimal(
        expectedAnnualValue,
        3
      );
    }
    annualDataObj[`annual_variance_${item}`] = calculateVariance(
      annualData[item],
      fields[systemIndex].format === "percentage"
        ? expectedAnnualValue / 100
        : expectedAnnualValue
    );

    dataObj = Object.assign(dataObj, annualDataObj);
  });

  if (quarterlyData) {
    quarterlyData.forEach((item, index) => {
      let quarterlyValue = item;
      if (fields[systemIndex].format === "percentage") {
        quarterlyValue *= 100;
      }

      const quarterlyDataObj: IDataValue = {};
      quarterlyDataObj[`quarterly_${indexObject.quarterly?.[index]}`] =
        !systemIndex.includes("helper")
          ? roundToDecimal(
              calculateNumber("divide", quarterlyValue, unit),
              decimal
            )
          : roundToDecimal(quarterlyValue, 3);

      if (isReplaceParent) {
        quarterlyDataObj[`quarterly_${indexObject.quarterly?.[index]}`] =
          roundToDecimal(quarterlyValue, 3);
      }

      dataObj = Object.assign(dataObj, quarterlyDataObj);
    });

    comparableIndexes?.quarterly.forEach((item) => {
      let expectedQuarterlyValue = expectedQuarterlyData
        ? arrayChunk(expectedQuarterlyData, 4).flatMap((dataItem) =>
            dataItem.concat(null)
          )[item]
        : 0;
      if (fields[systemIndex].format === "percentage") {
        expectedQuarterlyValue *= 100;
      }

      const quarterlyDataObj: IDataValue = {};
      quarterlyDataObj[`quarterly_original_${item}`] = !systemIndex.includes(
        "helper"
      )
        ? roundToDecimal(
            calculateNumber("divide", expectedQuarterlyValue, unit),
            decimal
          )
        : roundToDecimal(expectedQuarterlyValue, 3);

      if (isReplaceParent) {
        quarterlyDataObj[`quarterly_original_${item}`] = roundToDecimal(
          expectedQuarterlyValue,
          3
        );
      }

      const convertedQuarterlyData = arrayChunk(quarterlyData, 4).flatMap(
        (dataItem) => dataItem.concat(null)
      );

      quarterlyDataObj[`quarterly_variance_${item}`] = calculateVariance(
        convertedQuarterlyData[item],
        fields[systemIndex].format === "percentage"
          ? expectedQuarterlyValue / 100
          : expectedQuarterlyValue
      );

      dataObj = Object.assign(dataObj, quarterlyDataObj);
    });
  }

  return dataObj;
};

export const getTableColumns = (
  dateTitles: IDateTitles[],
  periods: IPeriods,
  comparableColumn?: string,
  comparableIndexes?: IAnnualQuarterly
) => {
  const totalHistoricalColumns =
    periods.historicalYears + periods.historicalQuarters;

  const columns = dateTitles.map((period, index) => {
    if (index >= 0 && index <= totalHistoricalColumns - 1) {
      return {
        Header: period.fmt,
        accessor: period.fmt.includes("Q")
          ? `quarterly_${index}`
          : `annual_${index}`,
        period: "historical",
        role: "",
        comparable: period.fmt.includes("Q")
          ? comparableIndexes?.quarterly.includes(index)
          : comparableIndexes?.annual.includes(index)
      };
    }
    if (totalHistoricalColumns <= index && index <= dateTitles.length - 1) {
      return {
        Header: period.fmt,
        accessor: period.fmt.includes("Q")
          ? `quarterly_${index}`
          : `annual_${index}`,
        period: "projection",
        role: "",
        comparable: period.fmt.includes("Q")
          ? comparableIndexes?.quarterly.includes(index)
          : comparableIndexes?.annual.includes(index)
      };
    }

    return {
      Header: period.fmt,
      accessor: period.fmt.includes("Q")
        ? `quarterly_${index}`
        : `annual_${index}`,
      role: "",
      comparable: comparableColumn === period.fmt
    };
  });

  if (columns.length < 12) {
    const numberOfEmptyColumns = 12 - columns.length;
    for (let i = 0; i < numberOfEmptyColumns; i++) {
      columns.push({
        Header: "",
        accessor: uuidv4(),
        role: "",
        comparable: false
      });
    }
  }

  return columns;
};

export const getRowType = (systemIndex: string) => {
  if (systemIndex.includes("helper_child")) {
    return "helper";
  }
  if (systemIndex.includes("helper")) {
    return "helper";
  }
  if (systemIndex.includes("child")) {
    return "child";
  }
};

export const convertData = (
  metadata: ICreateAnalysisResponse,
  versionData: IVersionDataJson,
  worksheet: number,
  unit: number,
  decimal: number,
  allData?: boolean
) => {
  const metaDataArray = Object.entries(metadata.meta_data).map((data: any) => ({
    system_index: data[0],
    interval: versionData.calculated_version[data[0]]?.interval,
    ...data[1]
  })) as IConvertedMetadata[];

  const filteredMetadataByWorksheet = allData
    ? metaDataArray
    : metaDataArray.filter((data: any) => data.worksheet === worksheet);

  const originalRows = Object.entries(versionData.calculated_version).filter(
    (item) => item[0] && item[1]
  );

  const helperRowsToReplaceParent = originalRows
    .filter(
      (item) =>
        item[0].includes("helper") &&
        !item[0].includes("child") &&
        versionData.version_meta_data.fields[item[0]].display_instead_of_parent
    )
    .map((item) => {
      const field = versionData.version_meta_data.fields[item[0]];

      const fieldData = {
        system_index: item[0],
        parent_system_index: getParentSystemIndex(item[0]),
        ...item[1]
      };

      if (item[0].includes("helper") && !item[0].includes("child")) {
        return {
          ...fieldData,
          display_instead_of_parent: field.display_instead_of_parent
        };
      }

      return fieldData;
    });

  const dataArray: any[] = originalRows.map((item) => {
    const field = versionData.version_meta_data.fields[item[0]];

    let annualQuarterlyData = convertAnnualQuarterlyData(
      item[1].value?.annual || [],
      item[1].value?.quarterly || [],
      versionData.version_meta_data.position_indexes,
      unit,
      decimal,
      item[0],
      versionData.version_meta_data.fields,
      versionData.version_meta_data.extra?.comparable_indexes,
      item[1].value.expectedAnnual || [],
      item[1].value.expectedQuarterly || []
    );

    const helperToReplaceParent = helperRowsToReplaceParent.find(
      (h) => h.parent_system_index === item[0]
    );

    if (helperToReplaceParent) {
      annualQuarterlyData = {
        ...annualQuarterlyData,
        ...convertAnnualQuarterlyData(
          helperToReplaceParent.value?.annual || [],
          helperToReplaceParent.value?.quarterly || [],
          versionData.version_meta_data.position_indexes,
          unit,
          decimal,
          item[0],
          versionData.version_meta_data.fields,
          versionData.version_meta_data.extra?.comparable_indexes,
          helperToReplaceParent.value.expectedAnnual || [],
          helperToReplaceParent.value.expectedQuarterly || [],
          true
        )
      };
    }

    const fieldData = {
      system_index: item[0],
      parent_system_index:
        !item[0].includes("helper") && !item[0].includes("child")
          ? item[0]
          : getParentSystemIndex(item[0]),
      historical_type: field?.historical_type,
      projection_type: field?.projection_type,
      current_group: item[1].logics_group ? item[1].logics_group : null,
      ...annualQuarterlyData
    };

    if (item[0].includes("helper") && !item[0].includes("child")) {
      return {
        ...fieldData,
        display_instead_of_parent: field.display_instead_of_parent
      };
    }

    return {
      ...fieldData
    };
  });

  const dataArrayNoChildren = dataArray.filter(
    (data: any) =>
      !data.system_index.includes("child") &&
      !data.system_index.includes("helper") &&
      !data.system_index.includes("plug")
  );

  const childrenDataArray = dataArray.filter(
    (data: any) =>
      data.system_index.includes("child") ||
      data.system_index.includes("helper") ||
      data.system_index.includes("plug")
  );

  const rowNameArray = Object.entries(versionData.version_meta_data.fields).map(
    (item: any, index: number) => ({
      system_index: item[0],
      order_number: item.order_number || null,
      ...item[1]
    })
  );

  const rowNameArrayNoChildren = rowNameArray.filter(
    (item: any) =>
      !item.system_index.includes("child") &&
      !item.system_index.includes("helper") &&
      !item.system_index.includes("plug")
  );

  const childrenRowNameArray = rowNameArray.filter(
    (item: any) =>
      item.system_index.includes("child") ||
      item.system_index.includes("helper") ||
      item.system_index.includes("plug")
  );

  const subRows: any[] = [];

  if (rowNameArray.every((rowName: any) => !rowName.order_number)) {
    const childRowNameArrayWithOrderNumber = childrenRowNameArray.map(
      (item: any, index: number) => {
        if (item.system_index.includes("plug")) {
          return {
            ...item,
            order_number: childrenRowNameArray.length + 1
          };
        }
        if (item.system_index.includes("helper")) {
          return {
            ...item,
            order_number: index === 0 ? index + 1 : index
          };
        }
        if (item.system_index.includes("child")) {
          return {
            ...item,
            order_number: index === 1 ? index + 1 : index
          };
        }
        return item;
      }
    );
    for (let i = 0; i < childRowNameArrayWithOrderNumber.length; i++) {
      subRows.push({
        ...childRowNameArrayWithOrderNumber[i],
        ...childrenDataArray.find(
          (item: any) =>
            item.system_index ===
            childRowNameArrayWithOrderNumber[i].system_index
        )
      });
    }
  } else {
    for (let i = 0; i < childrenRowNameArray.length; i++) {
      subRows.push({
        ...childrenRowNameArray[i],
        ...childrenDataArray.find(
          (item: any) =>
            item.system_index === childrenRowNameArray[i].system_index
        )
      });
    }
  }

  const convertedData = [] as any[];
  let obj: {
    subRows: typeof convertedData;
  } = {
    subRows: []
  };

  for (let i = 0; i < filteredMetadataByWorksheet.length; i++) {
    obj = {
      ...filteredMetadataByWorksheet[i],
      ...dataArrayNoChildren.find(
        (item: any) =>
          item.system_index === filteredMetadataByWorksheet[i].system_index
      ),
      ...rowNameArrayNoChildren.find(
        (item: any) =>
          item.system_index === filteredMetadataByWorksheet[i].system_index
      ),
      rowType: "parent",
      subRows: subRows
        .sort((sub1, sub2) => sub1.order_number - sub2.order_number)
        .filter((subRowData: any) => {
          const splittedSubRowSystemIndex = subRowData.system_index.split("_");
          const baseSystemIndex = `${splittedSubRowSystemIndex[0]}_${splittedSubRowSystemIndex[1]}`;

          return (
            baseSystemIndex === filteredMetadataByWorksheet[i].system_index
          );
        })
        .map((subRowData: any) => ({
          ...subRowData,
          rowType: getRowType(subRowData.system_index)
        }))
    };

    convertedData.push(obj);
  }

  return convertedData.sort(
    (row1, row2) => row1.order_number - row2.order_number
  );
};

export const generateSubIndex = (list: any[]) => {
  if (list.length === 0) return 0;
  const lastItem = list[list.length - 1];
  const splittedLastItem = lastItem.system_index.split("_");
  const index = Number.isNaN(
    parseFloat(splittedLastItem[splittedLastItem.length - 1])
  )
    ? 0
    : splittedLastItem[splittedLastItem.length - 1];
  return Number(index) + 1;
};

export const checkEditable = (
  hasQuarter: boolean,
  isAnnualCol: boolean,
  period: string,
  historicalType: string,
  projectionType: string
): boolean => {
  if (hasQuarter && !isAnnualCol) {
    if (period === "historical" && historicalType === "input") return true;
    if (period === "historical" && historicalType === "api") return true;
    if (period === "projection" && projectionType === "input") return true;
    if (period === "projection" && projectionType === "api") return false;
  }

  if (period === "historical" && historicalType === "input") return true;
  if (period === "historical" && historicalType === "api") return true;
  if (period === "projection" && projectionType === "input") return true;
  if (period === "projection" && projectionType === "api") return false;

  return false;
};

export const checkEditableForEmptyHistorical = (
  isProjection: boolean,
  cellValues: any,
  totalHistoricalColumns: number,
  totalProjectionColumns: number,
  cellIndex: number,
  overWrittenValues: IOverWrittenFieldsValues[],
  systemIndex: string
) => {
  const allRowValues = Object.entries(cellValues).reduce(
    (total: any, curr: any) => {
      if (curr[0].includes("annual") || curr[0].includes("quarterly")) {
        total.push(curr[1]);
      }
      return total;
    },
    []
  );

  const isAllHistoricalValuesEmpty = allRowValues
    .slice(0, totalHistoricalColumns)
    .every((item: any) => item === 0);

  const allNonEmptyProjectionValues = allRowValues
    .slice(
      totalHistoricalColumns,
      totalProjectionColumns + totalHistoricalColumns
    )
    .filter((item: any) => item !== 0);

  const lastNonEmptyProjectionIndex = allRowValues
    .slice()
    .reverse()
    .findIndex((item: any) => item !== 0);

  const finalIndex =
    lastNonEmptyProjectionIndex >= 0
      ? allRowValues.length - lastNonEmptyProjectionIndex
      : lastNonEmptyProjectionIndex;

  const lastIndexOfOverwrittenFieldsValues = Math.max(
    ...overWrittenValues
      .filter((item) => item.system_index === systemIndex)
      .map((item) => item.position_index)
  );
  const currentOverwrittenField = overWrittenValues.find(
    (item) => item.system_index === systemIndex
  );

  if (
    isProjection &&
    isAllHistoricalValuesEmpty &&
    cellIndex - 1 > lastIndexOfOverwrittenFieldsValues &&
    overWrittenValues.length > 0 &&
    currentOverwrittenField &&
    Number(currentOverwrittenField.value) !== 0
  ) {
    return false;
  }

  if (
    isProjection &&
    isAllHistoricalValuesEmpty &&
    allNonEmptyProjectionValues.length > 0
  ) {
    if (cellIndex === finalIndex) {
      return true;
    }
    if (cellIndex > finalIndex) {
      return false;
    }
  }

  if (isProjection && isAllHistoricalValuesEmpty) {
    return true;
  }

  return false;
};

export const renderTableCellBgColor = (
  formatted: boolean,
  projection: boolean,
  highlighted: boolean
) => {
  if (highlighted) return "bg-orange-highlight";
  if (formatted) return "bg-primary-50";
  if (projection) return "bg-blue-highlight";
  return "bg-white";
};

export const checkValueFormatConsistency = (str: string) => {
  const result = formatList.filter((item) => str.includes(item));
  return result.length <= 1;
};

export const convertInputRowData = (
  value: string,
  colId: string,
  systemIndex: string,
  columns: CustomColumn[],
  format: string,
  t: TFunction
) => {
  const isConsistent = checkValueFormatConsistency(value);
  if (!isConsistent) {
    message.error(t("error:inconsistent-format"));
    return [
      {
        colId,
        systemIndex,
        value: 0
      }
    ];
  }

  let dataArray: string[] = [];
  dataArray = value.split(/[\t | \s]+/);

  if (
    dataArray.includes("$") ||
    dataArray.includes("%") ||
    dataArray.includes("x")
  ) {
    dataArray = [...dataArray].filter(
      (item) => item !== "$" && item !== "%" && item !== "x"
    );
  }

  if (dataArray.length === 1) {
    return [
      {
        colId,
        systemIndex,
        value: dataArray[0]
      }
    ];
  }

  const removedFormatDataArray = dataArray
    .filter((item) => item)
    .map((item) => {
      if (item[0] === "(" && item[item.length - 1] === ")") {
        return -Math.abs(
          Number(item.replace(/[()]/g, "").replace(/[$|%|x|,]/g, ""))
        );
      }
      if (item.includes("%") && format !== "percentage") {
        return Number(item.replace(/[$|%|x|,]/g, "")) / 100;
      }
      return Number(item.replace(/[$|%|x|,]/g, ""));
    });

  if (removedFormatDataArray.some((item) => Number.isNaN(item))) {
    message.error(t("error:incorrect-data"));
    return [
      {
        colId,
        systemIndex,
        value: 0
      }
    ];
  }
  const totalHistoricalColumns = columns.filter(
    (col) => col.period === "historical"
  ).length;
  const totalProjectionColumns = columns.filter(
    (col) => col.period === "projection"
  ).length;
  const indexOfCol = columns.findIndex((col) => col.accessor === colId);
  const currentCol = columns.find((col) => col.accessor === colId);

  const remainingColumnsFromIndex =
    currentCol?.period === "historical"
      ? totalHistoricalColumns - (indexOfCol - 1)
      : totalHistoricalColumns + totalProjectionColumns - (indexOfCol - 1);

  const availableDataArray = removedFormatDataArray.slice(
    0,
    remainingColumnsFromIndex
  );

  const columnIdList = columns
    .slice(indexOfCol, indexOfCol + remainingColumnsFromIndex)
    .filter((item) => item.Header !== "")
    .map((item) => String(item.accessor));

  const result = availableDataArray.map((item, index) => ({
    colId: columnIdList[index],
    systemIndex,
    value: item
  }));

  return result;
};

export const getPositionIndex = (columnId: string) => {
  if (columnId.includes("annual") || columnId.includes("quarterly")) {
    const positionIndex = columnId.substring(
      columnId.indexOf("_") + 1,
      columnId.length
    );
    return positionIndex;
  }
  return "";
};

export const renderCellFormat = (
  columnId: string,
  cellValue: any,
  format: string
) => {
  if (columnId.includes("variance")) return "percentage";
  if (cellValue !== undefined) return format;
  return "number";
};

const checkComparableColumn = (column: any, index: number) =>
  column.accessor.includes(`annual_${index}`) ||
  (column.accessor.includes(`quarterly_${index}`) &&
    (!column.accessor.includes("original") ||
      !column.accessor.includes("variance")));

export const addComparableColumns = (
  comparableIndexes: IAnnualQuarterly,
  columns: any[]
) => {
  let newColumns: any[] = [];

  const indexes = [
    ...comparableIndexes.annual,
    ...comparableIndexes.quarterly
  ].sort((a, b) => a - b);

  if (indexes.length > 0) {
    indexes.forEach((index) => {
      const comparableColumn =
        newColumns.length > 0
          ? newColumns.find((item) => checkComparableColumn(item, index))
          : columns.find((item) => checkComparableColumn(item, index));
      const comparableColumnIndex =
        newColumns.length > 0
          ? newColumns.findIndex((item) => checkComparableColumn(item, index))
          : columns.findIndex((item) => checkComparableColumn(item, index));

      if (newColumns.length === 0) {
        columns[comparableColumnIndex] = {
          ...comparableColumn,
          comparable: true,
          role: "compare",
          isComparing: true,
          period: "historical"
        };
      } else {
        newColumns[comparableColumnIndex] = {
          ...comparableColumn,
          comparable: true,
          role: "compare",
          isComparing: true,
          period: "historical"
        };
      }

      if (comparableColumn) {
        const compareColumns = [
          {
            Header: `${comparableColumn.Header} (Original)`,
            accessor: comparableColumn.Header.includes("Q")
              ? `quarterly_original_${Number(index)}`
              : `annual_original_${Number(index)}`,
            period: "",
            role: "compare",
            isComparing: true
          },
          {
            Header: `${comparableColumn.Header} Variance`,
            accessor: comparableColumn.Header.includes("Q")
              ? `quarterly_variance_${Number(index)}`
              : `annual_variance_${Number(index)}`,
            period: "",
            role: "compare",
            isComparing: true
          }
        ];

        newColumns =
          newColumns.length === 0
            ? columns
                .slice(0, comparableColumnIndex + 1)
                .concat(compareColumns)
                .concat(columns.slice(comparableColumnIndex + 1))
            : newColumns
                .slice(0, comparableColumnIndex + 1)
                .concat(compareColumns)
                .concat(newColumns.slice(comparableColumnIndex + 1));
      }
    });
  }

  return newColumns;
};
