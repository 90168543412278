import loadable from "@loadable/component";

export const LoginPage = loadable(() => import("./login-page"));
export const RegisterPage = loadable(() => import("./register-page"));
export const AcceptInvitationPage = loadable(
  () => import("./accept-invitation-page")
);
export const ResetPasswordPage = loadable(
  () => import("./reset-password-page")
);
export const RequestResetPasswordPage = loadable(
  () => import("./request-reset-password-page")
);
export const EmailConfirmationPage = loadable(
  () => import("./email-confirmation-page")
);
