import { SVGProps } from "react";

export const LockOpenIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.99967 6.66666H12.4997V5C12.4997 4.30555 12.2566 3.71527 11.7705 3.22916C11.2844 2.74305 10.6941 2.49999 9.99967 2.49999C9.30523 2.49999 8.71495 2.74305 8.22884 3.22916C7.74273 3.71527 7.49967 4.30555 7.49967 5H5.83301C5.83301 3.84722 6.23926 2.86458 7.05176 2.05208C7.86426 1.23958 8.8469 0.833328 9.99967 0.833328C11.1525 0.833328 12.1351 1.23958 12.9476 2.05208C13.7601 2.86458 14.1663 3.84722 14.1663 5V6.66666H14.9997C15.458 6.66666 15.8504 6.82986 16.1768 7.15625C16.5031 7.48263 16.6663 7.875 16.6663 8.33333V16.6667C16.6663 17.125 16.5031 17.5174 16.1768 17.8437C15.8504 18.1701 15.458 18.3333 14.9997 18.3333H4.99967C4.54134 18.3333 4.14898 18.1701 3.82259 17.8437C3.4962 17.5174 3.33301 17.125 3.33301 16.6667V8.33333C3.33301 7.875 3.4962 7.48263 3.82259 7.15625C4.14898 6.82986 4.54134 6.66666 4.99967 6.66666ZM4.99967 16.6667H14.9997V8.33333H4.99967V16.6667ZM9.99967 14.1667C10.458 14.1667 10.8504 14.0035 11.1768 13.6771C11.5031 13.3507 11.6663 12.9583 11.6663 12.5C11.6663 12.0417 11.5031 11.6493 11.1768 11.3229C10.8504 10.9965 10.458 10.8333 9.99967 10.8333C9.54134 10.8333 9.14898 10.9965 8.82259 11.3229C8.4962 11.6493 8.33301 12.0417 8.33301 12.5C8.33301 12.9583 8.4962 13.3507 8.82259 13.6771C9.14898 14.0035 9.54134 14.1667 9.99967 14.1667Z"
      fill="url(#paint0_linear_169_4776)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_169_4776"
        x1="3.33301"
        y1="9.58333"
        x2="16.6663"
        y2="9.58333"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#458DE2" />
        <stop offset="1" stopColor="#00897B" />
      </linearGradient>
    </defs>
  </svg>
);
