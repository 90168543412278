import { IMember, IPermission, IAcceptedInvite } from "types/member.model";

interface InitialState {
  loadingMember: boolean;
  loadingPermission: boolean;
  loadingAcceptedInvites: boolean;
  members: IMember[];
  permissions: IPermission[];
  acceptedInvites: IAcceptedInvite[];
}

export const initialState: InitialState = {
  loadingMember: true,
  loadingPermission: true,
  loadingAcceptedInvites: true,
  members: [],
  permissions: [],
  acceptedInvites: []
};
