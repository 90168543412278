import { IDates, IChart, IConvertedChartData } from "types/dashboard.model";

/**
 * Converts bar and line chart data into a specific format.
 *
 * @param {IChart} data - The chart data to be converted.
 * @param {IDates} dates - The dates associated with the chart data.
 * @param {string} period - The period of the chart data.
 * @return {IConvertedChartData} The converted chart data.
 */
export const convertBarAndLineChartData = (
  data: IChart,
  dates: IDates,
  period: string
): IConvertedChartData => {
  const quarterDates = dates.quarterly || [];

  const datasets = data.data_providers.map((item) => {
    const chartData = {
      label: item.label,
      data: period === "annual" ? item.value.annual : item.value.quarterly,
      backgroundColor: item.color,
      borderColor: item.color,
      borderWidth: 3,
      fill: false
    };
    return chartData;
  });

  const convertedChartData = {
    type: data.primary_type,
    labels: period === "annual" ? dates.annual : quarterDates,
    datasets
  };

  return convertedChartData;
};
