import { ChangeEvent, useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CircularProgress from "@mui/material/CircularProgress";
import clsx from "clsx";

import { isEmpty } from "lodash";
import TextField from "../inputs/text-field";
import Option from "../select/option";
import { menuListStyles, paperStyles } from "./autocomplete.styles";

interface AutocompleteProps {
  loading?: boolean;
  label?: string;
  options: any[];
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSelect: (stock: string, currency: string) => void;
  className?: string;
  errorMessage?: string;
  name?: string;
  value?: string;
}

export default function Autocomplete({
  loading,
  label,
  options,
  onChange,
  onSelect,
  className,
  errorMessage,
  name,
  value
}: AutocompleteProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchPhrase, setSearchPhrase] = useState<string>("");

  useEffect(() => {
    if (value) {
      setSearchPhrase(value);
    }
  }, [value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event);
    setSearchPhrase(event.target.value);
  };
  const handleSelectOption = (stockSymbol: string, currency: string) => {
    setSearchPhrase(stockSymbol);
    onSelect(stockSymbol, currency);
    setIsOpen(false);
  };
  const handleFocus = () => {
    setIsOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className={clsx("relative", className && className)}>
        <TextField
          error={!isEmpty(errorMessage)}
          fullWidth
          size="small"
          name={name}
          label={label}
          value={searchPhrase}
          onChange={handleChange}
          onClick={handleFocus}
          helperText={errorMessage}
        />
        {isOpen && (
          <Paper elevation={0} style={paperStyles}>
            {loading ? (
              <div className="flex items-center justify-center">
                <CircularProgress size="1rem" />
              </div>
            ) : options.length === 0 ? (
              <p className="text-sm text-main">No results</p>
            ) : (
              <MenuList sx={menuListStyles}>
                {options.map((opt) => (
                  <Option
                    key={opt.company_name}
                    onClick={() =>
                      handleSelectOption(opt.stock_symbol, opt.currency)
                    }
                  >
                    {opt.company_name} - {opt.stock_symbol}
                  </Option>
                ))}
              </MenuList>
            )}
          </Paper>
        )}
      </div>
    </ClickAwayListener>
  );
}

Autocomplete.defaultProps = {
  loading: false,
  label: "",
  className: "",
  errorMessage: "",
  name: "",
  value: ""
};
