import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";

export const StyledTab = styled(Tab)(() => ({
  textTransform: "none",
  fontFamily: "'Inter', sans-serif",
  fontSize: "1rem",
  paddingLeft: 0,
  paddingRight: 0,
  paddingBottom: "18px",
  minWidth: "auto",
  color: "#636363",
  fontWeight: 400,
  "&.Mui-selected": {
    color: "#000000",
    fontWeight: 500
  }
}));
