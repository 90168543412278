import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";

export const StyledTextField = styled(TextField)(() => ({
  "& .MuiInputLabel-root": {
    fontFamily: "Inter"
  },
  "& .MuiInputBase-input": {
    fontFamily: "Inter"
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#ffffff"
  }
}));

export const StyledOutlinedInput = styled(OutlinedInput)(() => ({
  "& .MuiInputLabel-root": {
    fontFamily: "Inter"
  },
  "& .MuiInputBase-input": {
    fontFamily: "Inter"
  },
  backgroundColor: "#ffffff"
}));
