import { useState, MouseEvent, useEffect } from "react";
import { CompactPicker, ColorResult } from "react-color";
import Popover from "@mui/material/Popover";

import { ArrowDownIcon, ArrowUpIcon } from "icons";

import styles from "./color-picker.module.css";

interface ColorPickerProps {
  color: string;
  className?: string;
  onChange: (newColor: string) => void;
}

export default function ColorPicker({
  color,
  onChange,
  className
}: ColorPickerProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [currentColor, setCurrentColor] = useState<string>(color);

  useEffect(() => {
    setCurrentColor(color);
  }, [color]);

  const handleClickOpen = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (newColor: ColorResult) => {
    setCurrentColor(newColor.hex);
    onChange(newColor.hex);
  };

  return (
    <div className={className}>
      <div
        className="flex cursor-pointer items-center rounded-md border border-gray-cb py-1 pl-1 pr-2.5"
        onClick={handleClickOpen}
      >
        <div className={styles.inner} style={{ background: currentColor }} />
        {anchorEl ? <ArrowUpIcon width="10" /> : <ArrowDownIcon width="10" />}
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        PaperProps={{
          sx: { overflow: "hidden" }
        }}
        onClose={handleClickClose}
      >
        <CompactPicker color={currentColor} onChange={handleChange} />
      </Popover>
    </div>
  );
}

ColorPicker.defaultProps = {
  className: ""
};
