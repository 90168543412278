import { IAnalysisType } from "types/analysis-type.model";

interface InitialState {
  loading: boolean;
  analysisTypes: IAnalysisType[];
}

export const initialState: InitialState = {
  loading: true,
  analysisTypes: []
};
