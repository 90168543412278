import { SVGProps } from "react";

export const SettingsIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.5 14V16H6.5V14H0.5ZM0.5 2V4H10.5V2H0.5ZM10.5 18V16H18.5V14H10.5V12H8.5V18H10.5ZM4.5 6V8H0.5V10H4.5V12H6.5V6H4.5ZM18.5 10V8H8.5V10H18.5ZM12.5 6H14.5V4H18.5V2H14.5V0H12.5V6Z"
      fill="currentColor"
    />
  </svg>
);
