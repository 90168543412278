import qs from "qs";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import { IDates, IChart } from "types/dashboard.model";
import { ISubTemplate, IRow, IWorksheet } from "types/template.model";
import { convertFormulars } from "modules/admin/templates/templates-form/rows/rows.helpers";
import { ReactNode } from "react";

// jest.mock("uuid", () => ({ v4: () => "00000000-0000-0000-0000-000000000000" }));

/** check a variable whether it is empty */
export const isEmpty = (data: any) => {
  if (data === "" || data === null || data === undefined) {
    return true;
  }
  if (typeof data === "object" && !Array.isArray(data)) {
    return Object.keys(data).length === 0;
  }
  if (Array.isArray(data)) {
    return data.length === 0;
  }
  return false;
};

/** capitalize a string */
export const capitalizeWord = (str: string) => {
  if (!isEmpty(str)) {
    return str[0].toUpperCase() + str.slice(1);
  }
  return "";
};

/** convert data to bar and line chart js */
export const convertBarAndLineChartData = (
  data: IChart,
  dates: IDates,
  period: string
) => {
  const quarterDates = dates.quarterly ? dates.quarterly : [];

  const datasets = data.data_providers.map((item) => {
    if (period === "annual") {
      return {
        label: item.label,
        data: item.value.annual,
        backgroundColor: item.color,
        borderColor: item.color,
        borderWidth: 3,
        fill: false
      };
    }
    return {
      label: item.label,
      data: item.value.quarterly,
      backgroundColor: item.color,
      borderColor: item.color,
      borderWidth: 3,
      fill: false
    };
  });

  const convertedChartData = {
    type: data.primary_type,
    labels: period === "annual" ? dates.annual : quarterDates,
    datasets
  };

  return convertedChartData;
};

/** convert data to mixed chart js */
export const convertMixedChart = (
  data: IChart,
  dates: IDates,
  period: string
) => {
  const quarterDates = dates.quarterly ? dates.quarterly : [];

  const datasets = data.data_providers.map((item) => {
    if (period === "annual" && item.yaxis === "primary") {
      return {
        type: data.primary_type,
        label: item.label,
        data: item.value.annual,
        backgroundColor: item.color,
        borderColor: item.color,
        borderWidth: 3,
        fill: false,
        yAxisID: "primary"
      };
    }
    if (period === "annual" && item.yaxis === "secondary") {
      return {
        type: data.secondary_type,
        label: item.label,
        data: item.value.annual,
        backgroundColor: item.color,
        borderColor: item.color,
        borderWidth: 3,
        fill: false,
        yAxisID: "secondary"
      };
    }
    if (period === "quarters" && item.yaxis === "primary") {
      return {
        type: data.primary_type,
        label: item.label,
        data: item.value.quarterly,
        backgroundColor: item.color,
        borderColor: item.color,
        borderWidth: 1,
        fill: false,
        yAxisID: "primary"
      };
    }
    if (period === "quarters" && item.yaxis === "secondary") {
      return {
        type: data.secondary_type,
        label: item.label,
        data: item.value.quarterly,
        backgroundColor: item.color,
        borderColor: item.color,
        borderWidth: 1,
        fill: false,
        yAxisID: "secondary"
      };
    }
    return {
      type: data.primary_type,
      label: item.label,
      data: item.value.annual,
      backgroundColor: item.color,
      borderColor: item.color,
      borderWidth: 3,
      fill: false,
      yAxisID: "primary"
    };
  });

  const primaryData = datasets.filter((item) => item.yAxisID === "primary");
  const secondaryData = datasets.filter((item) => item.yAxisID === "secondary");

  const sortedDatasets = [...secondaryData, ...primaryData].sort(
    (a: any, b: any) => b.type?.localeCompare(a.type)
  );

  const convertedChartData = {
    labels: period === "annual" ? dates.annual : quarterDates,
    datasets: sortedDatasets
  };

  return convertedChartData;
};

/** serialize params for get request */
export const paramsSerializer = (params: any) => qs.stringify(params);

/** calculate number by unit (divide or multiply) */
export const calculateNumber = (
  type: string,
  number: number | string | undefined,
  unit: number
) => {
  if (number === undefined) return 0;

  switch (type) {
    case "divide":
      return Number(number) / unit;
    case "multiply":
      return Number(number) * unit;
    default:
      return Number(number);
  }
};

/** round number to a specified number of decimals */
export const roundToDecimal = (
  number: number | string | undefined,
  decimal: number
) => {
  if (number === undefined) return 0;
  return Number(Number(number).toFixed(Number(decimal)));
};

/** format number to have commas */
export const numberFormatter = (
  number: number | string | undefined,
  decimal: number,
  rowType: string
) => {
  if (number === undefined) return 0;
  const currentNumber = Number(number);

  if (decimal && rowType !== "helper") {
    return currentNumber.toLocaleString(undefined, {
      maximumFractionDigits: decimal,
      minimumFractionDigits: decimal
    });
  }
  if (rowType === "helper")
    return currentNumber.toLocaleString(undefined, {
      maximumFractionDigits: 3,
      minimumFractionDigits: 3
    });

  return currentNumber.toLocaleString();
};

/** format cell input to accept dollar sign, commas and percentage */
export const cellFormatter = (str: string) => {
  if (str.startsWith("$") || str.includes(",")) {
    return str.replace(/[$,]/g, "");
  }
  if (str.endsWith("%")) {
    const number = str.replace("%", "");
    return String(Number(number) / 100);
  }
  return str;
};

/** check array if there are any duplicates */
export const hasDuplicates = <T>(array: T[]): boolean => {
  if (isEmpty(array)) return false;
  return new Set(array).size !== array.length;
};

/** find an existing element in array from another array */
export const findExistingElement = <T>(array1: T[], array2: T[]): T | null => {
  if (isEmpty(array1) || isEmpty(array2)) return null;
  return array2.find((item) => array1.includes(item)) || null;
};

/** toast message */
export const message = {
  success: (str: string | ReactNode) =>
    toast.success(str, {
      theme: "colored"
    }),
  error: (str: string) =>
    toast.error(str, {
      theme: "colored"
    }),
  warn: (str: string) =>
    toast.warn(str, {
      theme: "colored"
    })
};

/** set first language for all translation */
export const setFirstLanguage = (
  title: { [key: string]: string },
  value: string
) => {
  let newTitle = { ...title };

  Object.keys(title).forEach((item) => {
    if (isEmpty(title[item])) {
      newTitle = {
        ...newTitle,
        [item]: value
      };
    }
  });

  return newTitle;
};

/** set English as default language if other languages is empty */
export const setEnglishAsDefaultLanguage = (title: {
  [key: string]: string;
}) => {
  const hasOtherLanguages = Object.keys(title).some((lang) => lang !== "en");
  let newTitle = { ...title };
  if (!hasOtherLanguages) {
    newTitle = {
      ...title,
      zh: title.en
    };

    return newTitle;
  }
  if (hasOtherLanguages) {
    return setFirstLanguage(title, title.en);
  }
  return newTitle;
};

/** find worksheet */
export const findWorksheet = (
  worksheets: IWorksheet[],
  worksheetId: number | null
) => {
  if (isEmpty(worksheetId) || isEmpty(worksheets)) return null;
  const currentWorksheet = worksheets.find((ws) => ws.id === worksheetId);
  if (currentWorksheet) {
    return {
      id: currentWorksheet.id,
      order_number: currentWorksheet.order_number
    };
  }
  return null;
};

/** convert subtemplates to rows */
export const convertSubtemplatesToRows = (
  subtemplates: ISubTemplate[],
  dataSource: "none" | "api"
): IRow[] => {
  if (subtemplates.length === 0) return [];

  const subTemplate = subtemplates.filter(
    (item) => item.data_source === dataSource
  )[0];

  return subtemplates
    .filter((item) => item.data_source === dataSource)[0]
    .fields.map((field, index) => {
      const globalId = uuidv4();
      return {
        id: uuidv4(),
        fieldId: field.id,
        subTemplateId: subTemplate.id,
        globalId,
        default_format: field.default_format,
        groups: convertFormulars(field, dataSource),
        title: setEnglishAsDefaultLanguage(field.title),
        available_on_assumption_page: field.available_on_assumption_page,
        system_index: field.system_index,
        main_periods_logic: field.main_periods_logic,
        interval: field.interval,
        plug_row_status: field.subfields.some((sub) => sub.type === "plug"),
        open: false,
        worksheet: field.worksheet,
        order_number: field.order_number || index + 1,
        data_source: dataSource as "none" | "api"
      };
    });
};

/** check for duplicated system index */
export const checkDuplicateSystemIndex = (rows: IRow[]) => {
  if (rows.length === 0) return false;

  const rowsFromOneDataSource = rows.filter(
    (row) => row.data_source === "none"
  );
  const systemIndexes = rowsFromOneDataSource.map((row) => row.system_index);
  const newSystemIndexes = new Set([...systemIndexes]);

  return systemIndexes.length !== newSystemIndexes.size;
};

/** check object values are all empty */
export const checkObjectHasEmptyValue = <T>(
  obj: Record<string | number, T>
): boolean => Object.values(obj).some((item) => isEmpty(item));

export const filterNonNull = (obj: any) =>
  Object.fromEntries(Object.entries(obj).filter(([k, v]) => v));
