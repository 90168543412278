import { SVGProps } from "react";

export const PublishedIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0413 11C13.0413 5.48 17.5212 1 23.0413 1C28.5613 1 33.0413 5.48 33.0413 11C33.0413 16.52 28.5613 21 23.0413 21C17.5212 21 13.0413 16.52 13.0413 11ZM15.2513 9.22C15.1213 9.78999 15.0413 10.39 15.0413 11C15.0413 15.07 18.1013 18.43 22.0312 18.93V17C20.9313 17 20.0312 16.1 20.0312 15V14L15.2513 9.22ZM27.0312 15C27.9313 15 28.6713 15.59 28.9313 16.4C30.2312 14.98 31.0413 13.08 31.0413 11C31.0413 7.64999 28.9613 4.77 26.0312 3.59V4C26.0312 5.10001 25.1312 6 24.0312 6H22.0312V8C22.0312 8.55 21.5813 9 21.0312 9H19.0312V11H25.0312C25.5813 11 26.0312 11.45 26.0312 12V15H27.0312ZM11.0412 10H5.37445C4.8222 10 4.37445 10.4477 4.37445 11V24H1C0.447693 24 0 24.4477 0 25V32C0 32.5522 0.447693 33 1 33H15.875C16.4272 33 16.875 32.5522 16.875 32V25.9999H22.8744C23.4268 25.9999 23.8744 25.5522 23.8744 24.9999V23H21.8744V23.9999H6.37445V12H11.0412V10ZM14.875 26H2V31H14.875V26Z"
      fill="currentColor"
    />
  </svg>
);
