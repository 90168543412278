import { IIndustry } from "types/industry.model";

interface InitialState {
  loading: boolean;
  industries: IIndustry[];
  activeIndustries: IIndustry[];
  sectorList: string[];
}

export const initialState: InitialState = {
  loading: true,
  industries: [],
  activeIndustries: [],
  sectorList: []
};
