import { createAsyncThunk } from "@reduxjs/toolkit";

import { authorizedRequest } from "services/request";
import { IParam } from "types/common.model";

export const getIndustries = createAsyncThunk(
  "industries/getIndustries",
  async (state: IParam, { rejectWithValue }) => {
    try {
      const response = await authorizedRequest.get(
        "/api/analyzes/industries/",
        { params: state }
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getActiveIndustries = createAsyncThunk(
  "industries/getActiveIndustries",
  async (state: IParam, { rejectWithValue }) => {
    try {
      const response = await authorizedRequest.get(
        "/api/analyzes/industries/active"
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getSectors = createAsyncThunk(
  "industries/getSectors",
  async (state, { rejectWithValue }) => {
    try {
      const response = await authorizedRequest.get(
        "/api/analyzes/industries/sectors"
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);
