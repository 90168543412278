import { SVGProps } from "react";

export const ProcessingIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
      fill="url(#paint0_linear_256_845)"
    />
    <path
      d="M10 11C10 10.4477 10.4477 10 11 10H17C17.5523 10 18 10.4477 18 11V17C18 17.5523 17.5523 18 17 18H11C10.4477 18 10 17.5523 10 17V11Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_256_845"
        x1="0"
        y1="14"
        x2="28"
        y2="14"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#458DE2" />
        <stop offset="1" stopColor="#00897B" />
      </linearGradient>
    </defs>
  </svg>
);
