import { Action, ThunkDispatch, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import industriesSlice from "./industries/industries.slice";
import analysisTypesSlice from "./analysis-types/analysis-types.slice";
import templatesSlice from "./templates.slice";
import rowLogicsSlice from "./row-logics/row-logics.slice";
import { analysesSlice } from "./analysis";
import subscriptionsSlice from "./subscription.slice";
import membersSlice from "./members/members.slice";
import { usersSlice } from "./users";
import { calculatorSlice } from "./calculator";
import { aiAssistantSlice } from "./ai-assistant";

export const store = configureStore({
  reducer: {
    template: templatesSlice,
    industries: industriesSlice,
    analysisTypes: analysisTypesSlice,
    rowLogics: rowLogicsSlice,
    analyses: analysesSlice,
    subscriptions: subscriptionsSlice,
    members: membersSlice,
    users: usersSlice,
    calculator: calculatorSlice,
    aiAssistant: aiAssistantSlice
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppDispatch = () => useDispatch<ThunkAppDispatch>();
