/**
 * Checks if a value is empty.
 *
 * @template T - The type of the input value.
 * @param {T} data - The value to check for emptiness.
 * @returns {boolean} True if the value is empty, false otherwise.
 *
 * @example
 * // Example usage:
 * // const result = isEmpty(''); // Returns true
 */
export const isEmpty = <T>(data: T): boolean => {
  if (data === null || data === undefined) {
    return true;
  }

  if (typeof data === "string" && data === "") {
    return true;
  }

  if (typeof data === "object" && !Array.isArray(data)) {
    return Object.keys(data).length === 0;
  }

  if (Array.isArray(data)) {
    return data.length === 0;
  }

  return false;
};
