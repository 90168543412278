import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./calculator.types";

const calculatorSlice = createSlice({
  name: "calculator",
  initialState,
  reducers: {},
  extraReducers: {}
});

export default calculatorSlice.reducer;
