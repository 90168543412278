export const getNewValues = (
  inputValue: number,
  methodValue: number,
  projectionValues: number[],
  numberOfHistoricalYears: number,
  currentValues: number[],
  format: string
) => {
  let tempValue =
    format === "percentage"
      ? (methodValue * 100 + Number(inputValue)) / 100
      : methodValue + Number(inputValue);
  const clonedProjectionValues = [...projectionValues];

  clonedProjectionValues.forEach((item, index) => {
    if (index > 0) {
      const calculation =
        format === "percentage"
          ? (clonedProjectionValues[index - 1] * 100 + Number(inputValue)) / 100
          : clonedProjectionValues[index - 1] + Number(inputValue);

      tempValue = calculation;
    }
    clonedProjectionValues[index] = tempValue;
  });

  const newValues = currentValues
    .slice(0, numberOfHistoricalYears)
    .concat(clonedProjectionValues);

  return newValues;
};
