import { ReactNode } from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";

interface ActionItemProps {
  disabled?: boolean;
  title: string;
  icon?: ReactNode;
  onClick: () => void;
}

export default function ActionItem({
  disabled,
  title,
  icon,
  onClick
}: ActionItemProps) {
  return (
    <MenuItem
      disabled={disabled}
      sx={{ paddingTop: 1, paddingBottom: 1 }}
      onClick={onClick}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <p className="font-sm font-semibold text-main">{title}</p>
    </MenuItem>
  );
}

ActionItem.defaultProps = {
  disabled: false
};
