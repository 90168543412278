import MuiButton, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import clsx from "clsx";
import { forwardRef } from "react";

interface CustomButtonProps extends ButtonProps {
  loading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, CustomButtonProps>(
  (
    { loading, disabled, children, className, ...props }: CustomButtonProps,
    ref
  ) => {
    const currentLanguage = localStorage.getItem("i18nextLng");

    return (
      <MuiButton
        disableElevation
        className={clsx(
          "flex items-center",
          className,
          currentLanguage !== "en" && "tracking-wider"
        )}
        disabled={loading || disabled}
        ref={ref}
        {...props}
      >
        {loading && <CircularProgress className="mr-2" size="0.875rem" />}
        {children}
      </MuiButton>
    );
  }
);

export default Button;

Button.defaultProps = {
  loading: false
};
