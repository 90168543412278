import { IVersionDataJson } from "types/version-data.model";

export const changeLogicGroup = (
  versionData: IVersionDataJson,
  childLogic: any,
  parentRowSystemIndex: string,
  group: string
) => {
  const currentVersionData = { ...versionData };
  const { parent_config } = childLogic;

  const value: any = {
    annual: Array(
      currentVersionData.calculated_version[parentRowSystemIndex].value.annual
        ?.length
    ).fill(0),
    quarterly: Array(
      currentVersionData.calculated_version[parentRowSystemIndex].value
        .quarterly?.length
    ).fill(0)
  };

  if (
    !currentVersionData.calculated_version[parentRowSystemIndex].value.quarterly
  ) {
    delete value.quarterly;
  }

  delete currentVersionData.version_meta_data.fields_dependencies[
    parentRowSystemIndex
  ].children;

  for (const key in currentVersionData.calculated_version) {
    if (key) {
      if (
        (key.includes("child") &&
          key.includes(`${parentRowSystemIndex}_child`) &&
          !key.includes("helper")) ||
        key.includes(`${parentRowSystemIndex}_child_helper`)
      ) {
        delete currentVersionData.calculated_version[key];
        delete currentVersionData.version_meta_data.fields[key];
      }

      if (childLogic && key.includes(`${parentRowSystemIndex}_plug`)) {
        delete currentVersionData.calculated_version[key];
        delete currentVersionData.version_meta_data.fields_dependencies[
          parentRowSystemIndex
        ].plug;
        delete currentVersionData.version_meta_data.fields[key];
      }
    }
  }

  if (
    !parent_config.parent_helper_default_types &&
    !parent_config.parent_helper_required
  ) {
    for (const key in currentVersionData.version_meta_data.fields) {
      if (
        key.includes("helper") &&
        !key.includes("child") &&
        key.includes(`${parentRowSystemIndex}_helper`)
      ) {
        delete currentVersionData.version_meta_data.fields[key];
        delete currentVersionData.calculated_version[key];
      }
    }

    delete currentVersionData.version_meta_data.fields_dependencies[
      parentRowSystemIndex
    ].helpers;
  }

  if (parent_config.parent_helper_default_types) {
    const newHelperSystemIndexes = Object.keys(
      parent_config.parent_helper_default_types
    );

    for (const key in currentVersionData.version_meta_data.fields) {
      if (
        key.includes("helper") &&
        !key.includes("child") &&
        !newHelperSystemIndexes.includes(key) &&
        key.includes(`${parentRowSystemIndex}_helper`)
      ) {
        delete currentVersionData.version_meta_data.fields[key];
        delete currentVersionData.calculated_version[key];
      }
    }

    Object.entries(parent_config.parent_helper_default_types).forEach(
      ([helperSystemIndex, helperValues]: any, index) => {
        const splittedHelperSystemIndex = helperSystemIndex.split("_");

        const helperIndex = Number.isNaN(
          parseFloat(
            splittedHelperSystemIndex[splittedHelperSystemIndex.length - 1]
          )
        )
          ? 1
          : Number(
              splittedHelperSystemIndex[splittedHelperSystemIndex.length - 1]
            ) + 1;

        currentVersionData.version_meta_data.fields = {
          ...currentVersionData.version_meta_data.fields,
          [helperSystemIndex]: {
            title: `${
              childLogic.parent_config.parent_helper_titles[helperSystemIndex]
            } ${
              Object.keys(childLogic.parent_config.parent_helper_default_types)
                .length === 1
                ? 1
                : helperIndex
            }`,
            order_number: index + 1,
            styles: ["indent"],
            format:
              parent_config.parent_helper_default_formats?.[
                helperSystemIndex
              ]?.[group] ?? "number",
            historical_type: helperValues.historical,
            projection_type: helperValues.projection
          }
        };

        currentVersionData.version_meta_data.fields_dependencies = {
          ...currentVersionData.version_meta_data.fields_dependencies,
          [parentRowSystemIndex]: {
            ...currentVersionData.version_meta_data.fields_dependencies[
              parentRowSystemIndex
            ],
            helpers: {
              ...currentVersionData.version_meta_data.fields_dependencies[
                parentRowSystemIndex
              ].helpers,
              [helperSystemIndex]: helperSystemIndex
            }
          }
        };

        currentVersionData.calculated_version = {
          ...currentVersionData.calculated_version,
          [helperSystemIndex]: {
            value
          }
        };
      }
    );
  }

  if (childLogic) {
    currentVersionData.version_meta_data.fields[parentRowSystemIndex] = {
      ...currentVersionData.version_meta_data.fields[parentRowSystemIndex],
      historical_type: childLogic.parent_config.parent_default_types.historical,
      projection_type: childLogic.parent_config.parent_default_types.projection
    };
  }

  return currentVersionData;
};
