import { SVGProps } from "react";

export const AssumptionsIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.5 16.6667V15.8333L4.16667 14.1667V16.6667C4.16667 16.9028 4.08681 17.1007 3.92708 17.2604C3.76736 17.4201 3.56944 17.5 3.33333 17.5C3.09722 17.5 2.89931 17.4201 2.73958 17.2604C2.57986 17.1007 2.5 16.9028 2.5 16.6667ZM5.83333 16.6667V12.5L7.5 10.8333V16.6667C7.5 16.9028 7.42014 17.1007 7.26042 17.2604C7.10069 17.4201 6.90278 17.5 6.66667 17.5C6.43056 17.5 6.23264 17.4201 6.07292 17.2604C5.91319 17.1007 5.83333 16.9028 5.83333 16.6667ZM9.16667 16.6667V10.8333L10.8333 12.5208V16.6667C10.8333 16.9028 10.7535 17.1007 10.5938 17.2604C10.434 17.4201 10.2361 17.5 10 17.5C9.76389 17.5 9.56597 17.4201 9.40625 17.2604C9.24653 17.1007 9.16667 16.9028 9.16667 16.6667ZM12.5 16.6667V12.5208L14.1667 10.8542V16.6667C14.1667 16.9028 14.0868 17.1007 13.9271 17.2604C13.7674 17.4201 13.5694 17.5 13.3333 17.5C13.0972 17.5 12.8993 17.4201 12.7396 17.2604C12.5799 17.1007 12.5 16.9028 12.5 16.6667ZM15.8333 16.6667V9.16667L17.5 7.5V16.6667C17.5 16.9028 17.4201 17.1007 17.2604 17.2604C17.1007 17.4201 16.9028 17.5 16.6667 17.5C16.4306 17.5 16.2326 17.4201 16.0729 17.2604C15.9132 17.1007 15.8333 16.9028 15.8333 16.6667ZM2.75 10.5833L7.14583 6.1875C7.46528 5.86806 7.86111 5.70833 8.33333 5.70833C8.80556 5.70833 9.20139 5.86806 9.52083 6.1875L11.6667 8.33333L16.0833 3.91667C16.25 3.75 16.4479 3.67014 16.6771 3.67708C16.9062 3.68403 17.1042 3.77083 17.2708 3.9375C17.4236 4.10417 17.4965 4.30208 17.4896 4.53125C17.4826 4.76042 17.4028 4.95139 17.25 5.10417L12.8542 9.5C12.5347 9.81944 12.1389 9.97917 11.6667 9.97917C11.1944 9.97917 10.7986 9.81944 10.4792 9.5L8.33333 7.35417L3.91667 11.7708C3.75 11.9375 3.55208 12.0174 3.32292 12.0104C3.09375 12.0035 2.89583 11.9167 2.72917 11.75C2.57639 11.5833 2.50347 11.3854 2.51042 11.1562C2.51736 10.9271 2.59722 10.7361 2.75 10.5833Z"
      fill="currentColor"
    />
  </svg>
);
