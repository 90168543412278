import { SVGProps } from "react";

export const ArrowUpIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="9"
    height="5"
    viewBox="0 0 9 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4.5 -3.49691e-07L8.5 5L0.5 5L4.5 -3.49691e-07Z" fill="#CBCBCB" />
  </svg>
);
