import { SVGProps } from "react";

export const ChatSendIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
      fill="url(#paint0_linear_2001_876)"
    />
    <path
      d="M6.83366 21L21.3753 14.7667C22.0503 14.475 22.0503 13.525 21.3753 13.2333L6.83366 7C6.28366 6.75833 5.67533 7.16667 5.67533 7.75834L5.66699 11.6C5.66699 12.0167 5.97533 12.375 6.39199 12.425L18.167 14L6.39199 15.5667C5.97533 15.625 5.66699 15.9833 5.66699 16.4L5.67533 20.2417C5.67533 20.8333 6.28366 21.2417 6.83366 21Z"
      fill="#E8EAED"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2001_876"
        x1="0"
        y1="14"
        x2="28"
        y2="14"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#458DE2" />
        <stop offset="1" stopColor="#00897B" />
      </linearGradient>
    </defs>
  </svg>
);
