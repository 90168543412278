interface EmptyBlockProps {
  message: string;
}

export default function EmptyBlock({ message }: EmptyBlockProps) {
  return (
    <div className="mb-6 flex h-10 w-full items-center justify-center rounded	 border border-dashed border-gray-cb">
      <p className="text-gray-cb">{message}</p>
    </div>
  );
}
