import { SVGProps } from "react";

export const FormulaPrefixIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.808594" width="30" height="30" rx="3" fill="#E3F2FE" />
    <path
      d="M8.16353 15.8327C7.74592 18.3285 8.07901 20.4066 9.03853 22.1765H10.4057C9.64009 20.864 9.307 18.1992 9.70472 15.8327C10.1025 13.4712 11.3056 10.8065 12.5037 9.49396H11.1415C9.58043 11.2638 8.57617 13.342 8.16353 15.8327ZM14.7621 12.4222H13.1314L14.8416 16.2404L11.8288 20.0586H13.4595L15.652 17.2049L16.8949 20.0586H18.5206L16.7557 16.2404L19.7734 12.4222H18.1477L15.9553 15.3952L14.7621 12.4222ZM23.5866 15.8377C24.0043 13.342 23.6712 11.2638 22.7116 9.49396H21.3445C22.1101 10.8065 22.4432 13.4712 22.0455 15.8377C21.6477 18.1992 20.4446 20.864 19.2464 22.1765H20.6087C22.1697 20.4066 23.174 18.3285 23.5866 15.8377Z"
      fill="#636363"
    />
  </svg>
);
