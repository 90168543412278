export const config = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL
};

export const TOKEN_ACCESS = "access.token";
export const TOKEN_REFRESH = "refresh.token";
export const METADATA_JSON = "metadata";

export const DEBOUNCE_TIME = 400;

export const LANGUAGES = [
  {
    title: "English",
    value: "en"
  },
  {
    title: "繁體中文",
    value: "zh"
  }
];

export const HCAPTCHA_SITE_KEY = "8ed3b1b0-e725-4953-9643-819dfd877e45";

export const LANDING_PAGE_URL = "https://www.prjanalytics.net/";
export const TERM_OF_USE_URL =
  "https://app.termly.io/document/terms-of-use-for-saas/8e6d732c-bc78-4797-8f99-008600819cc2";
export const PRIVACY_POLICY_URL =
  "https://app.termly.io/document/privacy-policy/a545fc28-f0b4-45e3-a881-0a55378f2006";
export const DISCLAIMER_URL =
  "https://app.termly.io/document/disclaimer/97a36b10-c184-4f5c-ac3d-a28c00f50349";
export const ACCEPTABLE_USE_POLICY_URL =
  "https://app.termly.io/document/acceptable-use/5a1bb178-3112-45c4-af18-45985db59ad9";

export const EMPTY_CELL = "—";

export const PAGE_SIZE_10 = 10;
export const PAGE_SIZE_5 = 5;

export const APP_VERSION = "1.8.2";
