import { SVGProps } from "react";

export const CircleAddIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5 11.02H11.5V15.02H9.5V11.02H5.5V9.02002H9.5V5.02002H11.5V9.02002H15.5V11.02ZM10.5 0.0200195C9.18678 0.0200195 7.88642 0.278677 6.67317 0.781224C5.45991 1.28377 4.35752 2.02037 3.42893 2.94895C1.55357 4.82432 0.5 7.36785 0.5 10.02C0.5 12.6722 1.55357 15.2157 3.42893 17.0911C4.35752 18.0197 5.45991 18.7563 6.67317 19.2588C7.88642 19.7614 9.18678 20.02 10.5 20.02C13.1522 20.02 15.6957 18.9665 17.5711 17.0911C19.4464 15.2157 20.5 12.6722 20.5 10.02C20.5 8.7068 20.2413 7.40644 19.7388 6.19318C19.2362 4.97993 18.4997 3.87754 17.5711 2.94895C16.6425 2.02037 15.5401 1.28377 14.3268 0.781224C13.1136 0.278677 11.8132 0.0200195 10.5 0.0200195Z"
      fill="#636363"
    />
  </svg>
);
