import { SVGProps } from "react";

export const AddIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10 0V20" stroke="currentColor" strokeWidth="2" />
    <path d="M20 10L0 10" stroke="currentColor" strokeWidth="2" />
  </svg>
);
