import { ReactElement } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { Chart, registerables } from "chart.js";
import "react-toastify/dist/ReactToastify.css";
import "suneditor/dist/css/suneditor.min.css";
import "react-phone-input-2/lib/material.css";

import { AppLayout, AuthLayout, AdminLayout } from "components";
import { Theme } from "theme";
import {
  PATH_BASE,
  PATH_LOGIN,
  PATH_AUTH_BASE,
  PATH_ADMIN_BASE,
  PATH_ANALYSIS,
  PATH_NOT_FOUND,
  PATH_ADMIN_DASHBOARD,
  PATH_REGISTER
} from "router/router.paths";
import { withAuthentication } from "hocs/withAuthentication";
import { NotFoundPage } from "pages/errors";
import { RegisterPage } from "pages/auth";

Chart.register(...registerables);

function App() {
  return (
    <Theme>
      <Router>
        <div className="App">
          <Switch>
            <Route
              exact
              path="/"
              render={(): ReactElement => <Redirect to={PATH_ANALYSIS} />}
            />
            <Route
              exact
              path="/app"
              render={(): ReactElement => <Redirect to={PATH_ANALYSIS} />}
            />
            <Route
              exact
              path={PATH_AUTH_BASE}
              render={(): ReactElement => <Redirect to={PATH_LOGIN} />}
            />
            <Route exact path={PATH_REGISTER} component={RegisterPage} />
            <Route exact={false} path={PATH_AUTH_BASE} component={AuthLayout} />
            <Route
              exact
              path={PATH_ADMIN_BASE}
              render={(): ReactElement => (
                <Redirect to={PATH_ADMIN_DASHBOARD} />
              )}
            />
            <Route
              exact={false}
              path={PATH_ADMIN_BASE}
              component={AdminLayout}
            />
            <Route
              exact={false}
              path={PATH_BASE}
              component={withAuthentication(AppLayout)}
            />
            <Route
              exact={false}
              path={PATH_NOT_FOUND}
              component={NotFoundPage}
            />
            <Redirect to={PATH_NOT_FOUND} />
          </Switch>
        </div>
      </Router>
    </Theme>
  );
}

export default App;
