import { SVGProps } from "react";

export const CopyAllIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_169_10236)">
      <path
        d="M15 1.66663H7.5C6.58333 1.66663 5.83333 2.41663 5.83333 3.33329V13.3333C5.83333 14.25 6.58333 15 7.5 15H15C15.9167 15 16.6667 14.25 16.6667 13.3333V3.33329C16.6667 2.41663 15.9167 1.66663 15 1.66663ZM15 13.3333H7.5V3.33329H15V13.3333ZM2.5 12.5V10.8333H4.16667V12.5H2.5ZM2.5 7.91663H4.16667V9.58329H2.5V7.91663ZM8.33333 16.6666H10V18.3333H8.33333V16.6666ZM2.5 15.4166V13.75H4.16667V15.4166H2.5ZM4.16667 18.3333C3.25 18.3333 2.5 17.5833 2.5 16.6666H4.16667V18.3333ZM7.08333 18.3333H5.41667V16.6666H7.08333V18.3333ZM11.25 18.3333V16.6666H12.9167C12.9167 17.5833 12.1667 18.3333 11.25 18.3333ZM4.16667 4.99996V6.66663H2.5C2.5 5.74996 3.25 4.99996 4.16667 4.99996Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_169_10236">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
