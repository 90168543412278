import { SVGProps } from "react";

export const CheckGreenIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="12"
      cy="12"
      r="12"
      transform="rotate(-90 12 12)"
      fill="#46C55A"
    />
    <path
      d="M10.1042 16.4375L6.0625 12.4167L7.125 11.3333L10.1042 14.3125L16.875 7.5625L17.9375 8.625L10.1042 16.4375Z"
      fill="white"
    />
  </svg>
);
