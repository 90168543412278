import { SVGProps } from "react";

export const RestartIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_169_9064)">
      <path
        d="M15.0003 10.8334C15.0003 9.45837 14.442 8.20837 13.5337 7.30004L14.717 6.11671C15.917 7.32504 16.667 8.99171 16.667 10.8334C16.667 14.2334 14.1253 17.0334 10.8337 17.4417V15.7584C13.192 15.3584 15.0003 13.3084 15.0003 10.8334ZM3.33366 10.8334C3.33366 7.15004 6.31699 4.16671 10.0003 4.16671C10.0503 4.16671 10.1003 4.17504 10.1503 4.17504L9.24199 3.26671L10.417 2.08337L13.3337 5.00004L10.417 7.91671L9.24199 6.74171L10.142 5.84171C10.092 5.84171 10.042 5.83337 10.0003 5.83337C7.24199 5.83337 5.00033 8.07504 5.00033 10.8334C5.00033 13.3084 6.80866 15.3584 9.16699 15.7584V17.4417C5.87533 17.0334 3.33366 14.2334 3.33366 10.8334Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_169_9064">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="matrix(-1 0 0 1 20 0)"
        />
      </clipPath>
    </defs>
  </svg>
);
