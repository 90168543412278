import { SVGProps } from "react";

export const StatementsIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.684 2.66319 17.0104 2.98958C17.3368 3.31597 17.5 3.70833 17.5 4.16667V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333ZM4.16667 7.5H15.8333V4.16667H4.16667V7.5ZM8.60417 11.6667H11.3958V9.16667H8.60417V11.6667ZM8.60417 15.8333H11.3958V13.3333H8.60417V15.8333ZM4.16667 11.6667H6.9375V9.16667H4.16667V11.6667ZM13.0625 11.6667H15.8333V9.16667H13.0625V11.6667ZM4.16667 15.8333H6.9375V13.3333H4.16667V15.8333ZM13.0625 15.8333H15.8333V13.3333H13.0625V15.8333Z"
      fill="currentColor"
    />
  </svg>
);
