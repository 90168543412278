import { createAsyncThunk } from "@reduxjs/toolkit";

import { authorizedRequest } from "services/request";

export const getChatHistory = createAsyncThunk(
  "AI Assistant/getChatHistory",
  async (state: { versionId: number; limit?: number }, { rejectWithValue }) => {
    const { limit, versionId } = state;
    try {
      const response = await authorizedRequest.get(
        `/api/data-sources/ai-chat-history/`,
        {
          params: {
            limit: limit || 10,
            offset: 0,
            version: versionId
          }
        }
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);
