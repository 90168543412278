import { SVGProps } from "react";

export const ArrowForwardIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_130_5447)">
      <path
        d="M8.00008 2.66667L7.06008 3.60667L10.7801 7.33334H2.66675V8.66667H10.7801L7.06008 12.3933L8.00008 13.3333L13.3334 8.00001L8.00008 2.66667Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_130_5447">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
