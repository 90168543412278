import { SVGProps } from "react";

export const SmallAddIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.814453" width="16" height="16" rx="3" fill="#636363" />
    <path
      d="M8.56152 8.33154H11.9316V9.78418H8.56152V13.6025H7.01758V9.78418H3.64746V8.33154H7.01758V4.80371H8.56152V8.33154Z"
      fill="white"
    />
  </svg>
);
