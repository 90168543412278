import { MouseEvent, ReactNode } from "react";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import ToggleButton from "./toggle-button";

type Option = {
  title: string | ReactNode;
  value: string | number;
};

interface ToggleButtonGroupProps {
  disabled?: boolean;
  value: string | number;
  onChange: (value: string) => void;
  options: Option[];
  className?: string;
}

export default function ToggleButtonGroup({
  disabled,
  value,
  onChange,
  options,
  className
}: ToggleButtonGroupProps) {
  const handleChange = (event: MouseEvent<HTMLElement>, newValue: string) => {
    onChange(newValue as string);
  };

  return (
    <MuiToggleButtonGroup
      disabled={disabled}
      color="primary"
      value={value}
      exclusive
      onChange={handleChange}
      className={className}
    >
      {options.map((item) => (
        <ToggleButton key={item.value} value={item.value}>
          {item.title}
        </ToggleButton>
      ))}
    </MuiToggleButtonGroup>
  );
}

ToggleButtonGroup.defaultProps = {
  className: ""
};
