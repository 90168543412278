import { SVGProps } from "react";

export const SquareArrowLeftIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    width="24"
    height="24"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M24,19V5a5.006,5.006,0,0,0-5-5H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19A5.006,5.006,0,0,0,24,19ZM9.465,17.707,5.879,14.121h0a3,3,0,0,1,0-4.243L9.465,6.293l.025-.024a1,1,0,1,1,1.389,1.438L7.586,11,18,10.993a1,1,0,0,1,0,2L7.587,13l3.292,3.293a1,1,0,1,1-1.414,1.414Z"
        fill="#636363"
        data-original="#000000"
      />
    </g>
  </svg>
);
