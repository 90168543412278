import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IAnalysisType } from "types/analysis-type.model";
import { getAnalysisTypes } from "./analysis-types.services";
import { initialState } from "./analysis-types.types";

const analysisTypesSlice = createSlice({
  name: "analysisTypes",
  initialState,
  reducers: {},
  extraReducers: {
    [getAnalysisTypes.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getAnalysisTypes.fulfilled.toString()]: (
      state,
      action: PayloadAction<IAnalysisType[]>
    ) => {
      state.loading = false;
      state.analysisTypes = [...action.payload].filter(
        (type) => type.name === "projection"
      );
    },
    [getAnalysisTypes.rejected.toString()]: (state) => {
      state.loading = false;
    }
  }
});

export default analysisTypesSlice.reducer;
