import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// English
import commonEn from "locales/en/common.json";
import loginEn from "locales/en/auth/login.json";
import registerEn from "locales/en/auth/register.json";
import appEn from "locales/en/app.json";
import errorEn from "locales/en/error.json";
import authEn from "locales/en/auth.json";

// Chinese
import commonZh from "locales/zh/common.json";
import loginZh from "locales/zh/auth/login.json";
import registerZh from "locales/zh/auth/register.json";
import appZh from "locales/zh/app.json";
import errorZh from "locales/zh/error.json";
import authZh from "locales/zh/auth.json";

// Vietnamese
import commonVn from "locales/vn/common.json";
import loginVn from "locales/vn/auth/login.json";
import appVn from "locales/vn/app.json";
import errorVn from "locales/vn/error.json";

// Thai
import commonTh from "locales/th/common.json";
import loginTh from "locales/th/auth/login.json";
import appTh from "locales/th/app.json";
import errorTh from "locales/th/error.json";

const currentLanguage = localStorage.getItem("i18nextLng");
let language = "en";

if (currentLanguage) {
  language = currentLanguage;
}

export const resources = {
  en: {
    common: commonEn,
    login: loginEn,
    app: appEn,
    error: errorEn,
    register: registerEn,
    auth: authEn
  },
  zh: {
    common: commonZh,
    login: loginZh,
    app: appZh,
    error: errorZh,
    register: registerZh,
    auth: authZh
  },
  vn: {
    common: commonVn,
    login: loginVn,
    app: appVn,
    error: errorVn
  },
  th: {
    common: commonTh,
    login: loginTh,
    app: appTh,
    error: errorTh
  }
} as const;

i18n.use(initReactI18next).use(LanguageDetector).init({
  lng: language,
  fallbackLng: "en",
  resources
});

export default i18n;
