import { IRowLogic } from "types/row-logic.model";

interface InitialState {
  loading: boolean;
  rowLogics: IRowLogic[];
}

export const initialState: InitialState = {
  loading: true,
  rowLogics: []
};
