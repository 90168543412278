import { SVGProps } from "react";

export const ReportIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5003 15.3333C12.2712 15.3333 12.075 15.2517 11.9118 15.0885C11.7486 14.9253 11.667 14.7292 11.667 14.5V13.25C11.667 13.0208 11.7486 12.8246 11.9118 12.6614C12.075 12.4983 12.2712 12.4167 12.5003 12.4167C12.7295 12.4167 12.9257 12.4983 13.0889 12.6614C13.2521 12.8246 13.3337 13.0208 13.3337 13.25V14.5C13.3337 14.7292 13.2521 14.9253 13.0889 15.0885C12.9257 15.2517 12.7295 15.3333 12.5003 15.3333ZM10.0003 15.3333C9.77116 15.3333 9.57498 15.2517 9.41178 15.0885C9.24859 14.9253 9.16699 14.7292 9.16699 14.5V9.49999C9.16699 9.27082 9.24859 9.07464 9.41178 8.91145C9.57498 8.74825 9.77116 8.66666 10.0003 8.66666C10.2295 8.66666 10.4257 8.74825 10.5889 8.91145C10.7521 9.07464 10.8337 9.27082 10.8337 9.49999V14.5C10.8337 14.7292 10.7521 14.9253 10.5889 15.0885C10.4257 15.2517 10.2295 15.3333 10.0003 15.3333ZM7.50033 15.3333C7.27116 15.3333 7.07498 15.2517 6.91178 15.0885C6.74859 14.9253 6.66699 14.7292 6.66699 14.5V11.5833C6.66699 11.3542 6.74859 11.158 6.91178 10.9948C7.07498 10.8316 7.27116 10.75 7.50033 10.75C7.72949 10.75 7.92567 10.8316 8.08887 10.9948C8.25206 11.158 8.33366 11.3542 8.33366 11.5833V14.5C8.33366 14.7292 8.25206 14.9253 8.08887 15.0885C7.92567 15.2517 7.72949 15.3333 7.50033 15.3333Z"
      fill="currentColor"
    />
    <path
      d="M4.99967 18.3333C4.54134 18.3333 4.14898 18.1701 3.82259 17.8437C3.4962 17.5174 3.33301 17.125 3.33301 16.6667V3.33332C3.33301 2.87499 3.4962 2.48263 3.82259 2.15624C4.14898 1.82985 4.54134 1.66666 4.99967 1.66666H10.9788C11.2011 1.66666 11.4129 1.70832 11.6143 1.79166C11.8156 1.87499 11.9927 1.99305 12.1455 2.14582L16.1872 6.18749C16.34 6.34027 16.458 6.51735 16.5413 6.71874C16.6247 6.92013 16.6663 7.13193 16.6663 7.35416V16.6667C16.6663 17.125 16.5031 17.5174 16.1768 17.8437C15.8504 18.1701 15.458 18.3333 14.9997 18.3333H4.99967ZM10.833 6.66666V3.33332H4.99967V16.6667H14.9997V7.49999H11.6663C11.4302 7.49999 11.2323 7.42013 11.0726 7.26041C10.9129 7.10068 10.833 6.90277 10.833 6.66666Z"
      fill="currentColor"
    />
  </svg>
);
