import { createAsyncThunk } from "@reduxjs/toolkit";

import { authorizedRequest } from "services/request";

export const getMembers = createAsyncThunk(
  "members/getMembers",
  async (state, { rejectWithValue }) => {
    try {
      const response = await authorizedRequest.get("/api/members/invitations/");
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getPermissionsByInvitedMember = createAsyncThunk(
  "members/getPermissionsByInvitedMember",
  async (state: { email: string }, { rejectWithValue }) => {
    const { email } = state;
    try {
      const response = await authorizedRequest.get(
        `/api/members/analysis-permissions/${email}/`
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getAcceptedInvites = createAsyncThunk(
  "members/getAcceptedInvites",
  async (state, { rejectWithValue }) => {
    try {
      const response = await authorizedRequest.get(
        "/api/members/accepted-invites/"
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);
