import { SVGProps } from "react";

export const MinusIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.5 16V2H2.5V16H16.5ZM16.5 0C17.0304 0 17.5391 0.210714 17.9142 0.585786C18.2893 0.960859 18.5 1.46957 18.5 2V16C18.5 16.5304 18.2893 17.0391 17.9142 17.4142C17.5391 17.7893 17.0304 18 16.5 18H2.5C1.96957 18 1.46086 17.7893 1.08579 17.4142C0.710714 17.0391 0.5 16.5304 0.5 16V2C0.5 0.89 1.4 0 2.5 0H16.5ZM14.5 8V10H4.5V8H14.5Z"
      fill="#636363"
    />
  </svg>
);
