/**
 * Calculates the median value of an array of numbers.
 *
 * @param {number[]} numbers - The array of numbers to calculate the median from.
 * @return {number} The median value of the input array.
 */
export function getMedianValue(numbers: number[]): number {
  // Sort the array in ascending order
  const sortedNumbers = numbers.slice().sort((a, b) => a - b);

  const len = sortedNumbers.length;
  const mid = Math.floor(len / 2);

  // If the array length is odd, return the middle element
  if (len % 2 !== 0) {
    return sortedNumbers[mid];
  }
  // If the array length is even, return the average of the two middle elements
  return (sortedNumbers[mid - 1] + sortedNumbers[mid]) / 2;
}
