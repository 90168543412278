import { SVGProps } from "react";

export const IndustryIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.33366 17.5C2.87533 17.5 2.48296 17.3368 2.15658 17.0104C1.83019 16.684 1.66699 16.2917 1.66699 15.8333V6.66666C1.66699 6.20832 1.83019 5.81596 2.15658 5.48957C2.48296 5.16318 2.87533 4.99999 3.33366 4.99999H6.66699V3.33332C6.66699 2.87499 6.83019 2.48263 7.15658 2.15624C7.48296 1.82985 7.87533 1.66666 8.33366 1.66666H11.667C12.1253 1.66666 12.5177 1.82985 12.8441 2.15624C13.1705 2.48263 13.3337 2.87499 13.3337 3.33332V4.99999H16.667C17.1253 4.99999 17.5177 5.16318 17.8441 5.48957C18.1705 5.81596 18.3337 6.20832 18.3337 6.66666V15.8333C18.3337 16.2917 18.1705 16.684 17.8441 17.0104C17.5177 17.3368 17.1253 17.5 16.667 17.5H3.33366ZM8.33366 4.99999H11.667V3.33332H8.33366V4.99999ZM16.667 12.5H12.5003V13.3333C12.5003 13.5694 12.4205 13.7674 12.2607 13.9271C12.101 14.0868 11.9031 14.1667 11.667 14.1667H8.33366C8.09755 14.1667 7.89963 14.0868 7.73991 13.9271C7.58019 13.7674 7.50033 13.5694 7.50033 13.3333V12.5H3.33366V15.8333H16.667V12.5ZM9.16699 12.5H10.8337V10.8333H9.16699V12.5ZM3.33366 10.8333H7.50033V9.99999C7.50033 9.76388 7.58019 9.56596 7.73991 9.40624C7.89963 9.24652 8.09755 9.16666 8.33366 9.16666H11.667C11.9031 9.16666 12.101 9.24652 12.2607 9.40624C12.4205 9.56596 12.5003 9.76388 12.5003 9.99999V10.8333H16.667V6.66666H3.33366V10.8333Z"
      fill="currentColor"
    />
  </svg>
);
