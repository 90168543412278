import { SVGProps } from "react";

export const LockIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.99967 18.3333C4.54134 18.3333 4.14898 18.1702 3.82259 17.8438C3.4962 17.5174 3.33301 17.125 3.33301 16.6667V8.33334C3.33301 7.87501 3.4962 7.48265 3.82259 7.15626C4.14898 6.82987 4.54134 6.66668 4.99967 6.66668H5.83301V5.00001C5.83301 3.84723 6.23926 2.86459 7.05176 2.05209C7.86426 1.23959 8.8469 0.833344 9.99967 0.833344C11.1525 0.833344 12.1351 1.23959 12.9476 2.05209C13.7601 2.86459 14.1663 3.84723 14.1663 5.00001V6.66668H14.9997C15.458 6.66668 15.8504 6.82987 16.1768 7.15626C16.5031 7.48265 16.6663 7.87501 16.6663 8.33334V16.6667C16.6663 17.125 16.5031 17.5174 16.1768 17.8438C15.8504 18.1702 15.458 18.3333 14.9997 18.3333H4.99967ZM4.99967 16.6667H14.9997V8.33334H4.99967V16.6667ZM9.99967 14.1667C10.458 14.1667 10.8504 14.0035 11.1768 13.6771C11.5031 13.3507 11.6663 12.9583 11.6663 12.5C11.6663 12.0417 11.5031 11.6493 11.1768 11.3229C10.8504 10.9965 10.458 10.8333 9.99967 10.8333C9.54134 10.8333 9.14898 10.9965 8.82259 11.3229C8.4962 11.6493 8.33301 12.0417 8.33301 12.5C8.33301 12.9583 8.4962 13.3507 8.82259 13.6771C9.14898 14.0035 9.54134 14.1667 9.99967 14.1667ZM7.49967 6.66668H12.4997V5.00001C12.4997 4.30557 12.2566 3.71529 11.7705 3.22918C11.2844 2.74307 10.6941 2.50001 9.99967 2.50001C9.30523 2.50001 8.71495 2.74307 8.22884 3.22918C7.74273 3.71529 7.49967 4.30557 7.49967 5.00001V6.66668Z"
      fill="#636363"
    />
  </svg>
);
