import { SVGProps } from "react";

export const CopyIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.8334 15.8334H6.66675C6.20842 15.8334 5.81605 15.6702 5.48966 15.3438C5.16328 15.0174 5.00008 14.625 5.00008 14.1667V2.50004C5.00008 2.04171 5.16328 1.64935 5.48966 1.32296C5.81605 0.996568 6.20842 0.833374 6.66675 0.833374H11.8126C12.0348 0.833374 12.2466 0.875041 12.448 0.958374C12.6494 1.04171 12.8265 1.15976 12.9792 1.31254L17.0209 5.35421C17.1737 5.50698 17.2917 5.68407 17.3751 5.88546C17.4584 6.08685 17.5001 6.29865 17.5001 6.52087V14.1667C17.5001 14.625 17.3369 15.0174 17.0105 15.3438C16.6841 15.6702 16.2917 15.8334 15.8334 15.8334ZM15.8334 6.66671H12.9167C12.5695 6.66671 12.2744 6.54518 12.0313 6.30212C11.7883 6.05907 11.6667 5.76393 11.6667 5.41671V2.50004H6.66675V14.1667H15.8334V6.66671ZM3.33341 19.1667C2.87508 19.1667 2.48272 19.0035 2.15633 18.6771C1.82994 18.3507 1.66675 17.9584 1.66675 17.5V6.66671C1.66675 6.4306 1.74661 6.23268 1.90633 6.07296C2.06605 5.91323 2.26397 5.83337 2.50008 5.83337C2.73619 5.83337 2.93411 5.91323 3.09383 6.07296C3.25355 6.23268 3.33341 6.4306 3.33341 6.66671V17.5H11.6667C11.9029 17.5 12.1008 17.5799 12.2605 17.7396C12.4202 17.8993 12.5001 18.0973 12.5001 18.3334C12.5001 18.5695 12.4202 18.7674 12.2605 18.9271C12.1008 19.0868 11.9029 19.1667 11.6667 19.1667H3.33341Z"
      fill="currentColor"
    />
  </svg>
);
