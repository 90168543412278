import { ReactNode, MouseEvent } from "react";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import clsx from "clsx";

import { CloseIcon } from "icons";
import { StyledDialog } from "./modal.styles";

interface ModalProps {
  open: boolean;
  width?: string | number;
  onClose?: () => void;
  title?: string | ReactNode;
  children: ReactNode;
  headerClassName?: string;
  closeOnClickOutside?: boolean;
  hideHeader?: boolean;
  className?: string;
  isClosable?: boolean;
}

export default function Modal({
  title,
  width,
  open,
  onClose,
  children,
  headerClassName,
  closeOnClickOutside,
  hideHeader,
  className,
  isClosable
}: ModalProps) {
  const dialogProps = closeOnClickOutside
    ? {
        open,
        onClose,
        PaperProps: { sx: { maxWidth: "fit-content" } }
      }
    : {
        open,
        onClose,
        PaperProps: { sx: { maxWidth: "fit-content" } },
        onClick: (e: MouseEvent<HTMLDivElement>) => e.stopPropagation()
      };

  return (
    <StyledDialog className={className} {...dialogProps}>
      <Grid sx={{ width: width || "28.75rem" }}>
        {!hideHeader && (
          <div
            className={clsx(
              "mb-3 flex items-center justify-between px-4 pt-3",
              headerClassName
            )}
          >
            {typeof title === "string" ? (
              <p className="text-lg font-semibold">{title || ""}</p>
            ) : (
              title
            )}
            {isClosable && (
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
        )}

        {children}
      </Grid>
    </StyledDialog>
  );
}

Modal.defaultProps = {
  title: "",
  width: ""
};
