import { SVGProps } from "react";

export const InfoIcon = ({
  strokeWidth,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.74023 7H11.7402V5H9.74023V7ZM10.7402 18C6.33023 18 2.74023 14.41 2.74023 10C2.74023 5.59 6.33023 2 10.7402 2C15.1502 2 18.7402 5.59 18.7402 10C18.7402 14.41 15.1502 18 10.7402 18ZM10.7402 0C9.42702 0 8.12666 0.258658 6.9134 0.761205C5.70014 1.26375 4.59775 2.00035 3.66917 2.92893C1.7938 4.8043 0.740234 7.34784 0.740234 10C0.740234 12.6522 1.7938 15.1957 3.66917 17.0711C4.59775 17.9997 5.70014 18.7362 6.9134 19.2388C8.12666 19.7413 9.42702 20 10.7402 20C13.3924 20 15.9359 18.9464 17.8113 17.0711C19.6867 15.1957 20.7402 12.6522 20.7402 10C20.7402 8.68678 20.4816 7.38642 19.979 6.17317C19.4765 4.95991 18.7399 3.85752 17.8113 2.92893C16.8827 2.00035 15.7803 1.26375 14.5671 0.761205C13.3538 0.258658 12.0535 0 10.7402 0ZM9.74023 15H11.7402V9H9.74023V15Z"
      fill="currentColor"
      strokeWidth={strokeWidth}
    />
  </svg>
);
