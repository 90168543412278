import { SVGProps } from "react";

export const TemplateIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="18"
      y="17"
      width="8"
      height="17"
      transform="rotate(90 18 17)"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="26"
      y="1"
      width="16"
      height="20"
      transform="rotate(90 26 1)"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
