import { SVGProps } from "react";

export const TwinkleIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.182292 8.80196C3.10192 8.80196 5.46875 6.43513 5.46875 3.5155C5.46875 3.41591 5.54824 3.33337 5.64784 3.33337H6.01883C6.11842 3.33337 6.19792 3.41591 6.19792 3.5155C6.19792 6.43513 8.56474 8.80196 11.4844 8.80196C11.5841 8.80196 11.6667 8.88152 11.6667 8.9812V9.35189C11.6667 9.45157 11.5841 9.53113 11.4844 9.53113C8.56474 9.53113 6.19792 11.898 6.19792 14.8176C6.19792 14.9174 6.11829 15 6.01852 15H5.64815C5.54838 15 5.46875 14.9174 5.46875 14.8176C5.46875 11.898 3.10192 9.53113 0.182292 9.53113C0.0826102 9.53113 0 9.45157 0 9.35189V8.9812C0 8.88152 0.0826102 8.80196 0.182292 8.80196Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6172 3.51562C14.4941 3.51562 16.0156 1.99409 16.0156 0.117188C16.0156 0.0531066 16.0668 0 16.1309 0H16.3691C16.4332 0 16.4844 0.0531066 16.4844 0.117188C16.4844 1.99409 18.0059 3.51562 19.8828 3.51562C19.9469 3.51562 20 3.56677 20 3.63085V3.86915C20 3.93323 19.9469 3.98438 19.8828 3.98438C18.0059 3.98438 16.4844 5.50591 16.4844 7.38281C16.4844 7.44689 16.4332 7.5 16.3691 7.5H16.1309C16.0668 7.5 16.0156 7.44689 16.0156 7.38281C16.0156 5.50591 14.4941 3.98438 12.6172 3.98438C12.5531 3.98438 12.5 3.93323 12.5 3.86915V3.63085C12.5 3.56677 12.5531 3.51562 12.6172 3.51562Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9764 15.1301C13.2704 15.1301 15.1301 13.2704 15.1301 10.9764C15.1301 10.8982 15.1925 10.8334 15.2708 10.8334H15.5623C15.6406 10.8334 15.703 10.8982 15.703 10.9764C15.703 13.2704 17.5627 15.1301 19.8566 15.1301C19.9351 15.1301 20 15.1927 20 15.2711V15.562C20 15.6404 19.9351 15.703 19.8566 15.703C17.5627 15.703 15.703 17.5627 15.703 19.8566C15.703 19.9351 15.6404 20 15.562 20H15.2711C15.1927 20 15.1301 19.9351 15.1301 19.8566C15.1301 17.5627 13.2704 15.703 10.9764 15.703C10.8982 15.703 10.8334 15.6406 10.8334 15.5623V15.2708C10.8334 15.1925 10.8982 15.1301 10.9764 15.1301Z"
      fill="currentColor"
    />
  </svg>
);
