import { ComponentType, FC } from "react";
import { Redirect } from "react-router-dom";
import decode from "jwt-decode";

import { PATH_ANALYSIS, PATH_LOGIN, PATH_NOT_FOUND } from "router/router.paths";
import { TOKEN_ACCESS } from "constants/config";

export const withAuthentication =
  <P extends any>(Component: ComponentType<P>): FC<P> =>
  (props) => {
    const accessToken = localStorage.getItem(TOKEN_ACCESS) as string;

    if (accessToken) {
      return <Component {...props} />;
    }
    return (
      <Redirect
        to={{
          pathname: PATH_LOGIN
        }}
      />
    );
  };

export const withAdminAuthentication =
  <P extends any>(Component: ComponentType<P>): FC<P> =>
  (props) => {
    const accessToken = localStorage.getItem(TOKEN_ACCESS) as string;
    const decodedToken: any = decode(accessToken);

    if (accessToken && decodedToken.role === "admin") {
      return <Component {...props} />;
    }
    if (accessToken && (!decodedToken.role || decodedToken.role !== "admin")) {
      return (
        <Redirect
          to={{
            pathname: PATH_NOT_FOUND
          }}
        />
      );
    }
    return (
      <Redirect
        to={{
          pathname: PATH_LOGIN
        }}
      />
    );
  };

export const withLoggedInAuthentication =
  <P extends any>(Component: ComponentType<P>): FC<P> =>
  (props) => {
    const accessToken = localStorage.getItem(TOKEN_ACCESS) as string;

    if (!accessToken) {
      return <Component {...props} />;
    }

    return (
      <Redirect
        to={{
          pathname: PATH_ANALYSIS
        }}
      />
    );
  };
