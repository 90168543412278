import { useTranslation } from "react-i18next";

import { Button } from "../button";
import Modal from "./modal";

interface DeleteModalProps {
  loading?: boolean;
  title?: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message?: string;
  confirmText?: string;
  cancelText?: string;
}

export default function DeleteModal({
  open,
  loading,
  title,
  onClose,
  onConfirm,
  message,
  confirmText,
  cancelText
}: DeleteModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      title={title || t("common:button.delete")}
      onClose={onClose}
      isClosable
    >
      <div className="px-4 pb-4">
        <p className="text-main">
          {message || "Are you sure you want to delete this?"}
        </p>
        <div className="mt-4 flex justify-end">
          <Button
            disableElevation
            variant="outlined"
            className="mr-4"
            onClick={onClose}
          >
            {cancelText}
          </Button>
          <Button
            loading={loading}
            disableElevation
            variant="contained"
            onClick={onConfirm}
          >
            {confirmText}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

DeleteModal.defaultProps = {
  message: "",
  title: "",
  confirmText: "Delete",
  cancelText: "Cancel",
  loading: false
};
