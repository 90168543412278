import { createAsyncThunk } from "@reduxjs/toolkit";

import { authorizedRequest } from "services/request";

export const getAnalysisTypes = createAsyncThunk(
  "analysisTypes/getAnalysisTypes",
  async (state, { rejectWithValue }) => {
    try {
      const response = await authorizedRequest.get(
        "/api/analyzes/templates-types/"
      );
      return response;
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);
