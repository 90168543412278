import { SVGProps } from "react";

export const MenuIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="23"
    height="16"
    viewBox="0 0 23 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.5" width="22" height="2" rx="1" fill="currentColor" />
    <rect x="0.5" y="7" width="22" height="2" rx="1" fill="currentColor" />
    <rect x="0.5" y="14" width="22" height="2" rx="1" fill="currentColor" />
  </svg>
);
