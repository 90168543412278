import { SVGProps } from "react";

export const EditNoteIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.79999 13.5641V11.8484H12V13.5641H4.79999ZM4.79999 9.98984V8.27422H15.6V9.98984H4.79999ZM4.79999 6.41562V4.7H15.6V6.41562H4.79999ZM13.2 18.425V15.7324L18.45 10.7523C18.571 10.6346 18.7054 10.5496 18.8532 10.4972C19.0011 10.4449 19.148 10.4187 19.294 10.4187C19.4532 10.4187 19.6059 10.4465 19.7518 10.5021C19.8978 10.5577 20.0305 10.6411 20.15 10.7523L21.25 11.8246C21.3598 11.9399 21.4456 12.068 21.5073 12.2089C21.5691 12.3498 21.6 12.4899 21.6 12.629C21.6 12.7682 21.5725 12.9105 21.5176 13.0559C21.4627 13.2014 21.3735 13.3311 21.25 13.4449L16.025 18.425H13.2ZM14.4 17.2812H15.525L18.4 14.541L17.85 13.993L17.3 13.4687L14.4 16.209V17.2812ZM17.85 13.993L17.3 13.4687L18.4 14.541L17.85 13.993Z"
      fill="#636363"
    />
  </svg>
);
