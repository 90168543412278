import { SVGProps } from "react";

export const ArrowDownIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="9"
    height="5"
    viewBox="0 0 11 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.49998 6.5L0.5 0.5H10.5L5.49998 6.5Z" fill="currentColor" />
  </svg>
);
