import { SVGProps } from "react";

export const SearchIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.18571 0C8.95888 0 10.6594 0.704386 11.9132 1.9582C13.167 3.21202 13.8714 4.91255 13.8714 6.68571C13.8714 8.34171 13.2646 9.864 12.2669 11.0366L12.5446 11.3143H13.3571L18.5 16.4571L16.9571 18L11.8143 12.8571V12.0446L11.5366 11.7669C10.364 12.7646 8.84171 13.3714 7.18571 13.3714C5.41255 13.3714 3.71202 12.667 2.4582 11.4132C1.20439 10.1594 0.5 8.45888 0.5 6.68571C0.5 4.91255 1.20439 3.21202 2.4582 1.9582C3.71202 0.704386 5.41255 0 7.18571 0ZM7.18571 2.05714C4.61429 2.05714 2.55714 4.11429 2.55714 6.68571C2.55714 9.25714 4.61429 11.3143 7.18571 11.3143C9.75714 11.3143 11.8143 9.25714 11.8143 6.68571C11.8143 4.11429 9.75714 2.05714 7.18571 2.05714Z"
      fill="#636363"
    />
  </svg>
);
