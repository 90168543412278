import MuiSelect, {
  SelectProps,
  SelectChangeEvent
} from "@mui/material/Select";

import { MenuProps, selectStyles } from "./select.styles";

interface CustomSelectProps extends SelectProps {
  onChange?: (event: SelectChangeEvent<any>) => void;
}

export default function Select({ onChange, sx, ...props }: CustomSelectProps) {
  return (
    <MuiSelect
      MenuProps={MenuProps}
      onChange={onChange}
      sx={{ ...selectStyles, ...sx }}
      {...props}
    />
  );
}

Select.defaultProps = {
  onChange: () => {}
};
