import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IRowLogic } from "types/row-logic.model";
import { initialState } from "./row-logics.types";
import { getRowLogics } from "./row-logics.services";

const rowLogicsSlice = createSlice({
  name: "rowLogics",
  initialState,
  reducers: {},
  extraReducers: {
    [getRowLogics.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getRowLogics.fulfilled.toString()]: (
      state,
      action: PayloadAction<IRowLogic[]>
    ) => {
      state.loading = false;
      state.rowLogics = [...action.payload];
    },
    [getRowLogics.rejected.toString()]: (state) => {
      state.loading = false;
    }
  }
});

export default rowLogicsSlice.reducer;
