import axios, { Canceler, AxiosResponse } from "axios";

import { config, TOKEN_ACCESS, TOKEN_REFRESH } from "constants/config";
import { paramsSerializer, message } from "helpers/lib";
import i18n from "i18n";
import authServices from "./auth.services";

let cancel: Canceler;
const accessToken = localStorage.getItem(TOKEN_ACCESS);

const authorizedRequest = axios.create({
  baseURL: config.apiBaseUrl,
  headers: {
    "Content-Type": "application/json"
  },
  cancelToken: new axios.CancelToken((c) => {
    if (cancel) cancel();
    cancel = c;
  })
});

const unauthorizedRequest = axios.create({
  baseURL: config.apiBaseUrl,
  headers: {
    "Content-Type": "application/json"
  }
});

authorizedRequest.interceptors.request.use(
  (axiosConfig) => {
    if (accessToken) {
      axiosConfig.headers.common.Authorization = `Bearer ${accessToken}`;
    }

    axiosConfig.paramsSerializer = (params: any) => paramsSerializer(params);

    return axiosConfig;
  },
  (error) => Promise.reject(error)
);

authorizedRequest.interceptors.response.use(
  (response: AxiosResponse<any>) => response.data,
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem(TOKEN_REFRESH);

    if (
      error.response &&
      error.response.status === 401 &&
      error.config &&
      !error.config.__isRetryRequest &&
      refreshToken
    ) {
      originalRequest._retry = true;

      const newAccessToken = await authServices.refreshAccessToken(
        refreshToken
      );

      localStorage.setItem(TOKEN_ACCESS, newAccessToken.access);
      authorizedRequest.defaults.headers.common.Authorization = `Bearer ${newAccessToken.access}`;
      originalRequest.headers.Authorization = `Bearer ${newAccessToken.access}`;
      if (
        originalRequest.method === "post" ||
        originalRequest.method === "patch" ||
        originalRequest.method === "put"
      )
        originalRequest.data = JSON.parse(originalRequest.data);
      return authorizedRequest(originalRequest);
    }
    if (error.response) {
      if (error.response && error.response.status === 500) {
        // message.error("Something is wrong");
        return Promise.reject(error);
      }

      if (error.response.data && error.response.data.industry) {
        message.error(error.response.data.industry[0]);
        return Promise.reject(error);
      }
      if (error.response.data && error.response.data.non_field_errors) {
        message.error(error.response.data.non_field_errors[0]);
        return Promise.reject(error);
      }
      if (error.response.data && error.response.data.code) {
        message.error(i18n.t(`error:${error.response.data.code}`));
        return Promise.reject(error);
      }
      if (
        error.response &&
        error.response.data.detail &&
        error.response.status === 403 &&
        error.response.config.url.includes("ai-chat")
      ) {
        return Promise.reject(error.response);
      }
      if (
        error.response.data &&
        error.response.data.name &&
        error.response.data.name?.length > 0
      ) {
        message.error(error.response.data.name[0]);
        return Promise.reject(error);
      }

      message.error(error.response.data.detail);
    }

    return Promise.reject(error);
  }
);

unauthorizedRequest.interceptors.response.use(
  (response: AxiosResponse<any>) => response.data,
  (error) => {
    if (error && error.response && error.response.status === 401) {
      message.error(error.response.data.detail);
      localStorage.clear();
      setTimeout(() => (window.location.href = "/auth/login"), 1000);
      return Promise.reject(error);
    }
    if (error && error.response) {
      message.error(error.response.data.detail);
      if (error.response.data.password) {
        error.response.data.password.forEach((err: any) => {
          message.error(err);
        });
      }
    }
    return Promise.reject(error.response.data);
  }
);

export { authorizedRequest, unauthorizedRequest };
